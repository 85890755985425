import { PureComponent } from 'react';
import { Text, Link, Label, DocumentTitle, Button, withKeyEvents, Loading } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';
import { accentColorForOrg } from '~/helpers/org-helper';
import { getNameFor } from '~/helpers/product-order-helper';
import sfApiNextRequest from '~/helpers/sf-api-next-request';
import K from '~/k';

import homeIcon from '~/assets/home-icon.png';
import rightArrowIcon from '~/assets/right-arrow-icon.png';

class HomePage extends PureComponent {
  state = {
    isCreatingOrder: false,
    isLoading: true
  };

  async componentDidMount() {
    var getHomepageDataResponse = await sfApiNextRequest({path: '/api/configurator/get-configurator-homepage-data', body: {orgId: this.props.session.activeOrg.id}});

    await this.props.trackProducts({products: _.get(getHomepageDataResponse, 'data.products'), reset: true});
    await this.props.trackProductCategories({productCategories: _.get(getHomepageDataResponse, 'data.productCategories'), reset: true});

    setTimeout(() => this.setState({isLoading: false}));
  }

  createProductOrder = async ({productCategoryId, productId, userId, isStocked, type = 'standalone'}) => {
    var productOrderProps = {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Order',
      productCategoryId,
      isStocked,
      userId,
      type,
      costs: {
        tax: 0,
        discount: 0,
        shipping: 0,
        orderTotal: 0,
        depositAmount: 0,
        orderSubTotal: 0,
        shippingIsTbd: true,
        depositPercentage: 0
      }
    };

    if (productId) _.set(productOrderProps, 'productId', productId);

    var {data: productOrder} = await api.request({uri: '/configurator/create-product-order', body: {productOrderProps}});

    this.props.trackProductOrders({productOrders: [productOrder]});

    //HINT need the setTimeout after trackProductOrders
    setTimeout(() => this.props.history.push(`/orders/${productOrder.orgSpecificId}`));
  };

  handleKeyDown = event => {
    if (event.key === 'Escape') {
      this.setState({isCreatingOrder: false, activeProductCategoryId: -1});
    }
  };

  handleSectionPress = ({sectionType, link, hasStockedOrders, userId}) => {
    var {activeProductCategoryId, isCreatingOrder} = this.state;

    if (sectionType === 'singleProduct') {
      //TODO if section only has one product, create order with productId. Do not show dropdown

      if (isCreatingOrder === false) {
        this.setState({isCreatingOrder: true, activeProductCategoryId: link.productCategoryId});
      }
      else if (isCreatingOrder === true) {
        if (link.productCategoryId === activeProductCategoryId) {
          this.setState({isCreatingOrder: false, activeProductCategoryId: -1});
        }
        else {
          this.setState({activeProductCategoryId: link.productCategoryId});
        }
      }
    }
    else if (sectionType === 'externalLink') {
      window.open(link.uri, '_blank');
    }
    else if (sectionType === 'multipleProducts') {
      this.createProductOrder({productCategoryId: link.productCategoryId, userId, isStocked: hasStockedOrders ? 1 : 0});
    }
  };

  render() {
    var {activeOrg} = this.props.session;
    var {isLoading} = this.state;
    const {productCategories, session: {user: {id: userId}}} = this.props;
    const products = _.filter(this.props.products, {'archived': 0});

    var getProductsForProductCategoryId = ({productCategoryId}) => {
      if (activeOrg.role === 'owner') {
        return _.filter(products, {productCategoryId});
      }
      else if (activeOrg.role === 'member') {
        var memberProducts = _.filter(products, {productCategoryId, 'publishLevel': 'internal'});

        memberProducts.push(_.filter(products, {productCategoryId, 'publishLevel': 'all'}));

        return _.flatten(memberProducts); //incase member products with 'all' is empty
      }
      else {
        return _.filter(products, {productCategoryId, 'publishLevel': 'all'});
      }
    };

    var srcFor = (filename) => `https://symbolic-public.s3.amazonaws.com/configuration-system/${filename}`;

    if (activeOrg.id === 850) { //HINT Meljac NA
      var sections = [
        {title: 'Purchase Stocked Items', accentColor: '#C7D2C3', type: 'multipleProducts', links: [
          {title: 'Back Boxes', thumbnailSrc: srcFor('product-category-5-thumb.png?version=2'), productCategoryId: 5},
          {title: 'Samples', thumbnailSrc: srcFor('product-category-6-thumb.png'), productCategoryId: 6}
        ], hasStockedOrders: 1},
        {title: 'Configure | Price | Quote', accentColor: '#BBCAD2', type: 'singleProduct', links: [
          {title: 'Keypads', thumbnailSrc: srcFor('product-category-1-thumb.png'), productCategoryId: 1},
          {title: 'Floor Outlets', thumbnailSrc: srcFor('product-category-2-thumb.png'), productCategoryId: 2},
          {title: 'Wall Outlets', thumbnailSrc: srcFor('product-category-3-thumb.png'), productCategoryId: 3},
          {title: 'Reading Lamps', thumbnailSrc: srcFor('product-category-4-thumb.png'), productCategoryId: 4},
          {title: 'Decora Cover Plates', thumbnailSrc: srcFor('product-category-13-thumb.png'), productCategoryId: 13},
        ], hasStockedOrders: 0},
        {title: 'Resources', accentColor: '#CDC5DD', type: 'externalLink', links: [
          {title: 'Price List', thumbnailSrc: srcFor('meljac-na/price-list-icon.png'), uri: srcFor('meljac-na/meljac-na-price-list.pdf')},
          {title: 'Product Information & Spec Sheets', thumbnailSrc: srcFor('Product-Info.png'), uri: 'https://meljac-na.com/resources/'},
          {title: 'Installation Resources', thumbnailSrc: srcFor('resource-1.png'), uri: 'https://meljac-na.com/resources/?tab=installation'},
          {title: 'Digital Catalogs', thumbnailSrc: srcFor('Catalog.png'), uri: 'https://meljac-na.com/resources/?tab=catalogs'},
          {title: 'Finish Options', thumbnailSrc: srcFor('Finish-Options.png'), uri: 'https://meljac-na.com/resources/finishes-2/'},
          {title: 'Sample Requests', thumbnailSrc: srcFor('Sample-Request.png'), uri: 'https://meljac-na.com/contact/?tab=sample-requests'},
          {title: 'Image Gallery', thumbnailSrc: srcFor('resource-3.png'), uri: 'https://meljac-na.com/gallery/'},
          {title: 'FAQs', thumbnailSrc: srcFor('resource-2.png'), uri: 'https://meljac-na.com/resources/?tab=faq'},
          {title: 'Feedback & Support', thumbnailSrc: srcFor('resource-5.png'), uri: 'https://meljac-na.com/contact/'},
        ], hasStockedOrders: 0}
      ];
    }
    else if (activeOrg.id === 1053) { //HINT Madera
      var sections = [
        // {title: 'Purchase Stocked Items', type: 'multipleProducts', accentColor: 'rgb(85, 122, 130)', links: [
        //   {title: 'Placeholder', productCategoryId: 1},
        // ]},
        {title: 'Purchase Made-To-Order Items', type: 'singleProduct', accentColor: '#43807A', links: [
          {title: 'Flooring', productCategoryId: 8},
          {title: 'Slab Doors', productCategoryId: 20},
          {title: 'Cabinet Fronts', productCategoryId: 21}
        ], hasStockedOrders: 0},
        // {title: 'Trade Resources', type: 'externalLink', accentColor: 'rgb(85, 122, 130)', links: [
        //   {title: 'Placeholder', productCategoryId: 1},
        // ]}
      ];
    }
    else if (activeOrg.id === 1392) {
      var sections = [
        {title: '', type: 'multipleProducts', accentColor: '#000', links: [
          {title: 'Lights', productCategoryId: 14},
          {title: 'Doors', productCategoryId: 16}
        ], hasStockedOrders: 0}
      ];
    }
    else if (activeOrg.id === 1450) { //deskmakers
      var sections = [
        {title: '', type: 'multipleProducts', accentColor: '#000', links: [
          {title: 'Overture', productCategoryId: 18}
        ], hasStockedOrders: 0}
      ];
    }

    var accentColor = accentColorForOrg({org: this.props.session.activeOrg});

    if (isLoading) {
      return (
        <Loading text={'Loading up\n\nThis can take a few seconds\n\nHold tight...'}/>
      );
    }
    else {
      return (
        <DocumentTitle title={'Configuration System'}>
          <View style={{flex: 1, position: 'relative'}}>
            <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: 50, paddingBottom: 50, justifyContent: 'center', minHeight: '100%'}}>
              <View style={{alignItems: 'center', marginBottom: K.spacing * 4}}>
                <Image source={homeIcon} style={{width: 30, height: 30, marginBottom: K.spacing * 2}}/>
                <Text style={{textAlign: 'center', marginTop: K.spacing, ...K.fonts.pageHeader}}>Welcome to the {activeOrg.title} {activeOrg.id === 850 ? 'Client Portal' : 'Configurator'}</Text>
              </View>
              {_.get(activeOrg, 'appData.designEngine.isArchitectural') ? (
                <TouchableOpacity
                  nativeID={'HomePageNewProjectButton'}
                  onPress={() => this.createProductOrder({userId, isStocked: 0, type: 'arch'})}
                  style={{backgroundColor: accentColor, borderRadius: K.borderRadius, padding: K.spacing, alignSelf: 'center'}}
                >
                  <Text style={{color: 'white'}}>New Project</Text>
                </TouchableOpacity>
              ) : (_.map(sections, (section, index) => (
                <View key={`section-${index}`} style={{alignItems: 'center', marginBottom: K.spacing * 2}}>
                  <Text style={{fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: 1, marginBottom: K.spacing}}>{section.title}</Text>
                  <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap', maxWidth: (section.maxItemsPerRow || 5) * (100 + K.margin)}}>
                    {_.map(section.links, (link, index) => {
                      var {activeProductCategoryId, isCreatingOrder} = this.state;
                      var isActiveProductCategory = activeProductCategoryId && activeProductCategoryId === link.productCategoryId;

                      return (
                        <View
                          key={`section-link-${index}`}
                          style={{marginBottom: K.spacing, ...((activeProductCategoryId && activeProductCategoryId !== -1 && !isActiveProductCategory) && {opacity: 0.5})}}
                        >
                          <View style={{position: 'relative'}}>
                            <TouchableOpacity
                              nativeID={`HomePageSectionButton-${_.kebabCase(link.title)}`}
                              key={`section-link-${index}`}
                              onPress={() => this.handleSectionPress({sectionType: section.type, link, userId, hasStockedOrders: section.hasStockedOrders})}
                              style={{width: 100, height: 80, marginRight: K.margin, backgroundColor: section.accentColor, borderRadius: K.borderRadius, alignItems: 'center', ...(isActiveProductCategory && {borderBottomRightRadius: 0, borderBottomLeftRadius: 0})}}
                            >
                              <View dataSet={{fadeOnHover: 1}}>
                                {productCategories[link.productCategoryId]?.fileUrl ? (
                                  <Image style={{width: 80, height: 80}} source={productCategories[link.productCategoryId].fileUrl}/>
                                ) : link.thumbnailSrc && (
                                  <Image style={{width: 80, height: 80}} source={link.thumbnailSrc}/>
                                )}
                              </View>
                            </TouchableOpacity>
                            {(activeProductCategoryId && isActiveProductCategory) && (isCreatingOrder) && (
                              <View style={{position: 'absolute', top: '100%', zIndex: 999}}>
                                {_.map(_.sortBy(getProductsForProductCategoryId({productCategoryId: this.state.activeProductCategoryId}), ['rank', 'id']), (product, index) => {
                                  var isFirst = index === 0;
                                  var isLast = index === getProductsForProductCategoryId({productCategoryId: activeProductCategoryId}).length - 1;

                                  return (
                                    <Button
                                      nativeID={`HomePageSectionProductButton-${product.id}`}
                                      key={`product-button-${index}`}
                                      dataSet={{'hover-background-double-gray': 1}}
                                      style={{backgroundColor: section.accentColor, paddingHorizontal: K.spacing, width: K.isWeb ? 150 : 120, borderBottomColor: 'rgba(255, 255, 255, 0.2)', borderTopColor: 'rgba(255, 255, 255, 0.2)', borderTopWidth: 1, borderRadius: 0, ...(isFirst && {borderTopRightRadius: 10}), ...(isLast && {borderBottomRightRadius: 10, borderBottomLeftRadius: 10})}}
                                      onPress={() => link.uri ? window.open(link.uri, '_blank') : this.createProductOrder({productCategoryId: link?.productCategoryId, productId: product.id, userId, isStocked: section.hasStockedOrders ? 1 : 0})}
                                    >
                                      <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.calc(4), width: '100%'}}>
                                        <Text style={{color: 'black', flex: 1, fontSize: K.calcFont(12)}}>{product.title}</Text>
                                      </View>
                                    </Button>
                                  );
                                })}
                              </View>
                            )}
                          </View>
                          <Text style={{fontSize: K.calcFont(12), textTransform: 'uppercase', textAlign: 'center', letterSpacing: 0.5, width: 100, padding: K.margin, marginTop: K.margin}}>{link.title}</Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )))}
              {/* <View style={{alignSelf: 'center', marginBottom: K.spacing * 10}}>
                {_.map([
                  'Create a purchase order or quote using a tile above',
                  'Then fill out your project information',
                  'Add items to your order',
                  'Configure your items, choosing from the available options',
                  'View pricing, your quote, and drawings',
                  'Submit & pay',
                  'Check back in for updates',
                ], (text, index) => (
                  <Text key={text} style={{marginBottom: K.spacing}}>{index + 1}. {text}</Text>
                ))}
              </View> */}
            </ScrollView>
            <Link
              to='/orders'
              style={{position: 'absolute', right: K.spacing * 2, top: '50%', marginTop: -35, alignItems: 'center'}}
            >
              <View
                dataSet={{growOnHover: 1}}
                style={{backgroundColor: accentColor, width: 70, height: 70, borderRadius: 70, alignItems: 'center', justifyContent: 'center'}}
              >
                <Image source={rightArrowIcon} nativeID={'HomePageOrdersButton'} style={{height: K.calc(23.54), width: K.calc(35.93)}} />
              </View>
              <Label style={{marginTop: K.spacing}}>{getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'My Orders'})}</Label>

            </Link>
          </View>
        </DocumentTitle>
      );
    }
  }
}

export default connect({
  mapState: (state) => {
    return {
      // productOrders: state.resources.productOrders.byId,
      productCategories: state.resources.productCategories.byId,
      products: state.resources.products.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['trackProductOrders']),
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories'])
  }
})(withKeyEvents(HomePage));

import { PureComponent } from 'react';
import { View, ScrollView, Image, TouchableOpacity } from 'react-native';
import { PickerInput, CheckboxInput, TextInput, Button, Popup, LabelledView, DocumentTitle, Text, Link, Label, Loading } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { accentColorForOrg } from '~/helpers/org-helper';

import _ from '@symbolic/lodash';
import AceEditor from 'react-ace';
import K from '~/k';
import handleFileUpload from '~/helpers/upload-file-helper';
import ProductOptionPopup from '~/components/popups/product-option-popup';

import createIconWhite from '~/assets/create-icon-white.png';
import settingsIcon from '~/assets/settings-icon.png';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';

import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import FileInput from '~/components/file-input';
import NumberInput from '~/components/number-input';
import AdminSection from '~/components/admin-section';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import productOption from '~/components/product-option';

class ProductOptionRow extends PureComponent {
  updateProductOptionRank = ({value, productOption}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productOptions, 'rank'), {id: productOption.id});
    var oldIds = _.map(_.sortBy(this.props.productOptions, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductOptions({updates});
    }
  };

  handleProductOptionDataUpdate = ({id, value, key}) => {
    var data = {...this.props.productOption.data, [key]: value};

    this.props.updateProductOption({id, props: {data}});
  };

  render() {
    var {productOption, index, productProperty} = this.props;

    var textCellStyle = {paddingHorizontal: K.spacing};
    var productOptionDataFields = _.get(productProperty, 'productOptionDataFields');
    var thumbnailUrl = productOption.thumbnailUrl;

    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity style={{width: 30, height: 30, marginLeft: K.spacing}} onPress={() => {
          this.props.setActiveProductOption({productOption});
          this.props.toggleProductOptionPopupIsVisible();
        }}>
          <Image source={thumbnailUrl ? (typeof(thumbnailUrl) === 'string' ? {uri: thumbnailUrl} : thumbnailUrl) : thumbnailPlaceholder} style={{width: '100%', height: '100%', borderRadius: 20}}/>
          <View style={{borderColor: 'rgba(0, 0, 0, 0.2)', borderWidth: 1, width: '100%', height: '100%', borderRadius: '50%', position: 'absolute', top: 0, left: 0}}/>
        </TouchableOpacity>
        <View
          dataSet={{'hover-background-double-gray': 1, 'conditional-opacity-parent': 1}}
          style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
        >
          <TextInput
            value={index + 1}
            onChange={({value})=> this.updateProductOptionRank({value, productOption})}
            style={{backgroundColor: 'transparent', alignSelf: 'center', width: 75, paddingHorizontal: K.spacing, textAlign: 'center', opacity: 0.5 }}
            selectTextOnFocus
          />
          <View style={{paddingLeft: K.spacing, flexDirection: 'row', flex: 1, height: '100%', alignItems: 'center'}}>
            {this.props.session.activeOrg.id !== 1798 && (<>
              <Text style={{width: 75, opacity: 0.5, ...textCellStyle}}>{productOption.id}</Text>
              <TextInput
                value={_.get(productOption, 'title')}
                onChange={({value}) => this.props.updateProductOption({id: productOption.id, props: {title: value}})}
                style={{width: 200, backgroundColor: 'transparent'}}
              />
            </>)}
            {_.map(productOptionDataFields, (productOptionDataField, index) => {
              var productOptionDataFieldInput;

              if (productOptionDataField.type === 'text') {
                productOptionDataFieldInput = (
                  <TextInput
                    value={_.get(productOption, `data.${productOptionDataField.key}`)}
                    onChange={({value}) => this.handleProductOptionDataUpdate({id: productOption.id, value, key: productOptionDataField.key})}
                    style={{width: 100, backgroundColor: 'transparent'}}
                  />
                );
              }
              if (productOptionDataField.type === 'number') {
                productOptionDataFieldInput = (
                  <NumberInput
                    value={_.get(productOption, `data.${productOptionDataField.key}`)}
                    onChange={({value}) => this.handleProductOptionDataUpdate({id: productOption.id, value, key: productOptionDataField.key})}
                    style={{width: 100, backgroundColor: 'transparent'}}
                  />
                );
              }
              if (productOptionDataField.type === 'boolean') {
                productOptionDataFieldInput = (
                  <CheckboxInput
                    value={_.get(productOption, `data.${productOptionDataField.key}`)}
                    onChange={() => this.handleProductOptionDataUpdate({id: productOption.id, value: !_.get(productOption, `data.${productOptionDataField.key}`), key: productOptionDataField.key})}
                    labelStyles={{display: 'none'}}
                    checkboxStyle={{borderLeftWidth: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    style={{alignSelf: 'center', ...(_.get(productOption, `data.${productOptionDataField.key}`) === true ? {} : {backgroundColor: 'none'})}}
                  />
                );
              }

              return <View key={index} style={{width: 120}}>{productOptionDataFieldInput}</View>;
            })}
            <TouchableOpacity style={{position: 'absolute', right: 10, opacity: 0.5, ...K.defaultIconSize}} onPress={() => {
              this.props.setActiveProductOption({productOption});
              this.props.toggleProductOptionPopupIsVisible();
            }}>
              <Image source={settingsIcon} style={{...K.defaultIconSize}} dataSet={{'conditional-opacity': 1}} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

class AdminProductPropertyPage extends PureComponent {
  state = {
    isProductPropertySettingsPopupVisible: false,
    uploading: false,
    uploadWasSuccessful: false,
    uploadHasFailed: false,
    filterMode: 'active',
    productOptionPopupIsVisible: false,
    productOptionDataFields: [],
    productOptionDataFieldsString: '',
    isLoading: true
  };

  async componentDidMount() {
    var {productPropertyId} = this.props;
    var orgId = this.props.session.activeOrg.id;

    var productProperties = await api.get('productProperties', {where: {id: productPropertyId, orgId}});
    var productOptions = await api.get('productOptions', {where: {productPropertyId, orgId}});
    var productRules = await api.get('productRules', {where: {productPropertyId, orgId}});

    await this.props.trackProductProperties({productProperties});
    await this.props.trackProductOptions({productOptions});
    await this.props.trackProductRules({productRules});

    setTimeout(() => {
      this.setState({isLoading: false});
      this.setState({productOptionDataFields: this.props.productProperty.productOptionDataFields});
      this.setState({productOptionDataFieldsString: this.props.productProperty && (typeof(this.props.productProperty.productOptionDataFields) === 'object' ? !_.keys(this.props.productProperty.productOptionDataFields).length ? '' : JSON.stringify(this.props.productProperty.productOptionDataFields) : this.props.productProperty.productOptionDataFields)});
    });
  }

  handleFilterModeChange = ({value}) => this.setState({filterMode: value});

  handleTitleChange = ({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {title: value}});

  handleInternalTitleChange = ({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {internalTitle: value}});

  toggleProductOptionPopupIsVisible = () => this.setState({productOptionPopupIsVisible: true});

  createProductOption = async () => {
    var maxRank = _.filter(this.props.productOptions, 'rank').length;

    var productOption = await api.create('productOption', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Option',
      productPropertyId: this.props.productProperty.id,
      rank: maxRank + 1
    });

    this.props.trackProductOptions({productOptions: [productOption]});
  };

  archive = async () => {
    this.props.updateProductProperty({id: this.props.productProperty.id, props: {archived: this.props.productProperty.archived ? 0 : 1}});
  };

  updateProductOptionRank = ({value, productOption}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productOptions, 'rank'), {id: productOption.id});
    var oldIds = _.map(_.sortBy(this.props.productOptions, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductOptions({updates});
    }
  };

  duplicateProperty = async () => {
    var {productProperty, productOptions} = this.props;

    var productProperty = await api.create('productProperty', {
      ..._.omit(productProperty, ['id', 'created', 'lastUpdated', 'lastUpdatedBy', 'deleted', 'archived', 'internalTitle']),
      title: `${productProperty.title} (copy)`
    });

    this.props.trackProductProperties({productProperties: [productProperty]});

    await this.props.createProductOptions({propsSets: _.map(productOptions, productOption => {
      return {
        ..._.omit(productOption, ['id', 'created', 'lastUpdated', 'lastUpdatedBy', 'deleted', 'archived']),
        productPropertyId: productProperty.id
      };
    })});

    setTimeout(() => {
      if (confirm('This property has been successfully duplicated. Would you like to be redirect to the new property?')) {
        this.props.history.push(`/admin/product-properties/${productProperty.id}`);
      }
    });
  };

  updateProductOptionDataFields = () => {
    var value = this.state.productOptionDataFieldsString;
    var id = this.props.productProperty.id;

    try {
      value = JSON.parse(value);

      this.props.updateProductProperty({id, props: {productOptionDataFields: value}});
    } catch (err) {
      console.error('value is not json.');
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <Loading />
          </View>
        </View>
      );
    }
    else {
      var {productProperty, productOptions} = this.props;
      var {filterMode} = this.state;

      var isSymbolicFrameworksMember = _.includes([1, 2, 3, 781, 971, 975], this.props.session.user.id);

      var dimensionData = {
        maxDimension: 0,
        minDimension: 0,
        dimensionInterval: 0
      };

      if (productProperty.type === 'dimensions' && !productProperty.data) {
        this.props.updateProductProperty({id: this.props.productProperty.id, props: {data: dimensionData}});
      }

      var productOptions = _.filter(productOptions, productOption => {
        if (filterMode === 'active') return !productOption.archived;
        if (filterMode === 'archived') return !!productOption.archived;
        if (filterMode === 'all') return true;
      });

      var productPropertyTypeOptions = [
        {value: 'singleSelect', title: 'Single Select'},
        {value: 'text', title: 'Text Input'},
        // {value: 'picker', title: 'Picker Input'},
        {value: 'dimensions', title: 'Dimension Input'}
      ];

      var {title, internalTitle, productOptionDataFields} = productProperty;

      var productOptionDataFieldColumnTitles = _.map(productOptionDataFields, productOptionDataField => {
        return {title: productOptionDataField.title, style: {width: 120, ...(productOptionDataField.type === 'boolean' ? {textAlign: 'center'} : {})}};
      });

      return (
        <DocumentTitle title={`Admin: ${productProperty.title} - Property`}>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <AdminMenu activeOrg={this.props.session.activeOrg} />
            <View style={{flex: 1}}>
              <SecondaryHeader
                title={title}
                titleIsEditable
                onTitleChange={this.handleTitleChange}
                subTitle={`${internalTitle ? internalTitle + '\n' : ''}ID: ${productProperty.id}`}
                rightComponent={(
                  <View style={{alignItems: 'flex-end', flexDirection: 'row'}}>
                    <Button dark label='Duplicate' onPress={this.duplicateProperty} style={{marginRight: K.spacing}}/>
                    <LabelledView label='Option Status' styles={{paddingTop: K.spacing, top}}>
                      <PickerInput
                        style={{height: K.inputHeight}}
                        buttonStyle={{backgroundColor: K.colors.doubleGray}}
                        showDownArrow={true}
                        options={[
                          {value: 'active', title: 'Active'},
                          {value: 'archived', title: 'Archived'},
                          {value: 'all', title: 'All'},
                        ]}
                        value={this.state.filterMode}
                        onChange={this.handleFilterModeChange}
                      />
                    </LabelledView>
                  </View>
                )}
                belowHeaderComponent={(
                  <View style={{marginTop: K.spacing * 2}}>
                    <Button
                      icon={settingsIcon}
                      style={{...K.defaultIconSize, backgroundColor: 'transparent', opacity: 0.5}}
                      onPress={() => this.setState({isProductPropertySettingsPopupVisible: true})}
                    />
                  </View>
                )}
              ></SecondaryHeader>
              {this.state.productOptionPopupIsVisible && (
                <ProductOptionPopup
                  onClose={() => this.setState({productOptionPopupIsVisible: false})}
                  mode={'admin'}
                  productOption={this.state.activeProductOption}
                  updateProductOption={() => this.props.updateProductOption()}
                />
              )}
              {this.state.isProductPropertySettingsPopupVisible && (
                <Popup onClose={() => this.setState({isProductPropertySettingsPopupVisible: false, uploading: false, uploadWasSuccessful: false, uploadHasFailed: false})}>
                  <TextInput
                    style={{...K.fonts.pageHeader, paddingHorizontal: K.spacing, paddingVertical: K.spacing, backgroundColor: 'white', marginBottom: K.spacing}}
                    value={productProperty.title}
                    onChange={({value}) => this.handleTitleChange({value})}
                    blurOnEnter
                    blurOnSubmit
                    multiline
                    standardAutoheightStyles
                    returnKeyType='done'
                  />
                  <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: K.spacing}}>
                    <FileInput
                      containerStyle ={{height: 64}}
                      uploading={this.state.uploading}
                      uploadWasSuccessful={this.state.uploadWasSuccessful}
                      uploadHasFailed = {this.state.uploadHasFailed}
                      handleFilesPicked={(files) => handleFileUpload({files,
                        setState: (newState) => this.setState(newState),
                        api,
                        resource: this.props.productProperty,
                        session: this.props.session,
                        resourceKey: 'productProperty',
                        trackResource: ({productProperties}) => this.props.trackProductProperties({productProperties})
                      })}
                    >
                      <Image source={productProperty.thumbnailUrl ? {uri: productProperty.thumbnailUrl} : thumbnailPlaceholder} style={{height: 40, width: 40, border: '1px solid black', borderRadius: 25}}/>
                    </FileInput>
                  </View>
                  <LabelledView gray label={'Internal Title'} style={{outerView: {marginBottom: K.spacing}}}>
                    <TextInput value={productProperty.internalTitle} onChange={({value}) => this.handleInternalTitleChange({value})}/>
                  </LabelledView>
                  <CheckboxInput
                    label={'Show thumbnails on invoice'}
                    value={this.props.productProperty['showThumbnailsOnInvoice']}
                    onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {showThumbnailsOnInvoice: value}})}
                    style={{marginBottom: K.spacing}}
                  />
                  <CheckboxInput
                    label={'Is order level'}
                    value={this.props.productProperty['isOrderLevel']}
                    onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {isOrderLevel: value}})}
                    style={{marginBottom: K.spacing}}
                  />
                  <LabelledView gray label={'Drawing Visibility'} style={{outerView: {marginBottom: K.spacing}}}>
                    <PickerInput
                      value={this.props.productProperty['drawingVisibility']}
                      options={[{title: 'Visible', value: 'visible'}, {title: 'None', value: 'none'}]}
                      onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {drawingVisibility: value}})}
                    />
                  </LabelledView>
                  <PickerInput
                    showDownArrow
                    style={{height: K.inputHeight}}
                    buttonStyle={{backgroundColor: K.colors.gray}}
                    value={this.props.productProperty['invoiceVisibility']}
                    options={[
                      {value: 'none', title: 'None'},
                      {value: 'inline', title: 'Default - Inline'},
                      {value: 'column', title: 'Column'}
                    ]}
                    onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {invoiceVisibility: value}})}
                  />
                  <AdminSection contentStyle={{flexDirection: 'column'}}>
                    <PickerInput
                      showDownArrow
                      style={{height: K.inputHeight, flex: 1}}
                      buttonStyle={{backgroundColor: K.colors.gray}}
                      options={productPropertyTypeOptions}
                      value={productProperty.type}
                      onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {type: value}})}
                    />
                    {productProperty.type !== 'singleSelect' && (
                      <View>
                        <CheckboxInput
                          label={'Is Array'}
                          value={productProperty.isArray}
                          onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {isArray: value}})}
                          style={{marginVertical: K.margin * 2, flex: 1}}
                        />
                      </View>
                    )}
                    {productProperty.type === 'singleSelect' && (
                      <View>
                        <CheckboxInput
                          label={'Hide Option Thumbnails'}
                          value={productProperty.noOptionThumbnails ? productProperty.noOptionThumbnails : 0}
                          onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {noOptionThumbnails: value}})}
                          style={{marginVertical: K.margin, flex: 1}}
                        />
                      </View>
                    )}
                  </AdminSection>
                  {isSymbolicFrameworksMember && (<>
                    <LabelledView label={'Product Option Data Fields'} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: K.spacing}}>
                      <AceEditor
                        mode="json"
                        theme="github"
                        value={this.state.productOptionDataFieldsString}
                        // onChange={(value) => this.handleDataChange({value})}
                        // onBlur={() => this.updateData({value: this.state.script})}
                        onChange={(value) => this.setState({productOptionDataFieldsString: value})}
                        onBlur={() => this.updateProductOptionDataFields({id: productProperty.id, value: this.state.productOptionDataFieldsString})}
                        height={'100px'} width={'100%'}
                        setOptions={{
                          useWorker: false,
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true,
                          tabSize: 2
                        }}
                      />
                    </LabelledView>
                  </>)}
                  <Button
                    style={{marginTop: K.spacing}}
                    light
                    onPress={this.archive}
                    label={this.props.productProperty.archived === 1 ? 'UNARCHIVE' : 'ARCHIVE'}
                  />
                </Popup>
              )}
              <ScrollView>
                <ScrollView
                  horizontal
                  contentContainerStyle={{flexDirection: 'column', padding: K.spacing}}
                  showsHorizontalScrollIndicator={false}
                >
                  {productProperty.type === 'singleSelect' && (
                    <>
                      <View style={{paddingLeft: K.spacing * 2 + K.spacing + 30, flexDirection: 'row', alignItems: 'flex-end', marginRight: K.spacing}}>
                        {_.map([
                          {title: 'Rank', style: {width: 75}},
                          ...(this.props.session.activeOrg.id !== 1798 ? [
                            {title: 'ID', style: {width: 75}},
                            {title: 'Title', style: {width: 200}}
                          ] : []),
                          ...productOptionDataFieldColumnTitles
                        ], ({title, style}, h) => (
                          <View key={h} style={{...style, padding: K.spacing}}>
                            <Label>{title}</Label>
                          </View>
                        ))}
                      </View>
                      {_.map(_.sortBy(productOptions, ['rank', 'id']), (productOption, index) => (
                        <ProductOptionRow
                          {...{productOption, productOptions, productProperty, index}}
                          {..._.pick(this.props, ['updateProductOption', 'updateProductOptions', 'session'])}
                          key={productOption.id}
                          toggleProductOptionPopupIsVisible={() => this.toggleProductOptionPopupIsVisible()}
                          setActiveProductOption={({productOption}) => this.setState({activeProductOption: productOption})}
                        />
                      ))}
                    </>
                  )}
                  {productProperty.type === 'text' && (
                    <Text>This property will display a text input.</Text>
                  )}
                  {productProperty.type === 'dimensions' && (
                    <>
                      <NumberInput
                        standardAutoheightStyles
                        label={'Maximum Dimension'}
                        style={{width: 250, marginBottom: K.spacing}}
                        labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                        placeholder={'Enter highest valid value'}
                        value={_.get(productProperty, 'data.maxDimension', dimensionData.maxDimension)}
                        onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {data: {...this.props.productProperty.data, maxDimension: value}}})}
                      />
                      <NumberInput
                        standardAutoheightStyles
                        label={'Minimum Dimension'}
                        style={{width: 250, marginBottom: K.spacing}}
                        labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                        placeholder={'Enter lowest valid value'}
                        value={_.get(productProperty, 'data.minDimension', dimensionData.maxDimension)}
                        onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {data: {...this.props.productProperty.data, minDimension: value}}})}
                      />
                      <NumberInput
                        standardAutoheightStyles
                        label={'Step Interval'}
                        style={{width: 250, marginBottom: K.spacing}}
                        labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                        placeholder={'Enter interval between valid values'}
                        value={_.get(productProperty, 'data.dimensionInterval', dimensionData.dimensionInterval)}
                        onChange={({value}) => this.props.updateProductProperty({id: this.props.productProperty.id, props: {data: {...this.props.productProperty.data, dimensionInterval: value}}})}
                      />
                    </>
                  )}
                </ScrollView>
              </ScrollView>
              <TouchableOpacity
                onPress={this.createProductOption}
                style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), width: 70, height: 70, borderRadius: 70, alignItems: 'center', 'justifyContent': 'center'}}
              >
                <Image source={createIconWhite} style={{width: K.calc(35), height: K.calc(35)}}/>
              </TouchableOpacity>
            </View>
          </View>
        </DocumentTitle>
      );
    }
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productPropertyId = parseInt(ownProps.match.params.productPropertyId);
    var productProperty = _.get(state.resources.productProperties.byId, productPropertyId);
    var productProperties = _.get(state, 'resources.productProperties.byId');
    var productOptions = _.filter(state.resources.productOptions.byId, {productPropertyId});

    return {
      productProperties,
      productProperty,
      productOptions
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productProperties, ['updateProductProperty', 'destroyProductProperty', 'trackProductProperties']),
    ..._.pick(resourceActions.productOptions, ['updateProductOptions', 'updateProductOption', 'createProductOption', 'trackProductOptions', 'destroyProductOption', 'createProductOptions']),
    ..._.pick(resourceActions.productRules, ['trackProductRules']),
  }
})(AdminProductPropertyPage);

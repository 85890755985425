import _ from 'lodash';
import getEngraving from '~/helpers/meljac/get-engraving';
import getCustomMeljacEngravingProductGraphicNodes from '~/helpers/meljac/get-custom-meljac-engraving-product-graphic-nodes';

export default function getKeypadMechanisms({deps, propertiesDataById, collection, layoutStringArray, path, rect, text, image, circle, group, mechanismFinish, xSpacing, ySpacing, totalColumns, buttonsYPosition, mechanisms, engravingsData, dimension, plateFinish, rectHeight, totalRows, justifyPosition, ledIndicatorIsEnabled, ledPosition}) {
  var mechanisms = [];
  var hasCustomEngravings = _.has(_.get(engravingsData, 'engravingArrays'), 'engravings');

  var classiqueButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -6, y: -6}, [
      circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 6,
      }),
      circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 4,
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 6, horizontalOffset: 6})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 6, horizontalOffset: 6, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
          )
        : []
      )
    ]),
  ]);

  var classiqueToggle = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -6, y: -6}, [
      circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 6,
      }),
      circle({
        cx: 6,
        cy: 6,
        fill: 'black',
        fillOpacity: 0.2,
        strokeWidth: 0.5,
        stroke: 'black',
        r: 4,
      }),
      // path({d: [
      //   {command: 'M', x: 0, y: 0},
      //   {command: 'C', x1: -2, y1: 0.65, x2: 0.6, y2: -0.45, x: 0, y: 2},
      //   {command: 'C', x1: 0, y1: 3.3, x2: 2, y2: 3.3, x: 2, y: 2},
      //   {command: 'C', x1: 1.45, y1: -0.45, x2: 4, y2: 0.65, x: 2, y: 0},
      //   {command: 'Z'}
      // ], fill: 'black', fillOpacity: 0.3, stroke: 'black', strokeWidth: 0.5, x: 5, y: 6}),
      circle({
        cx: 6,
        cy: 6,
        fill: 'black',
        fillOpacity: 0.5,
        strokeWidth: 0.5,
        stroke: 'black',
        r: 3,
      }),
      circle({
        cx: 6,
        cy: 4,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 3,
      }),
      circle({
        cx: 6,
        cy: 4,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
        opacity: 0.5,
        r: 2.5
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 6, horizontalOffset: 6})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 6, horizontalOffset: 6, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
          )
        : []
      )
    ]),
  ]);

  var damierSquareButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -7.2, y: -7.2}, [
      rect({
        width: 14.4,
        height: 14.4,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
      }),
      rect({
        x: 0.25,
        y: 0.25,
        width: 13.9,
        height: 13.9,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 8, horizontalOffset: 7.2})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 7.2, horizontalOffset: 7.2, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows, })
          )
        : []
      )
    ]),
  ]);

  var damierCircleButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -7.2, y: -7.2}, [
      circle({
        cx: 7.2,
        cy: 7.2,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 7.2,
      }),
      circle({
        cx: 7.2,
        cy: 7.2,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
        r: 6.2,
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 8, horizontalOffset: 7.2})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 6, horizontalOffset: 7.2, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '1'})
          )
        : []
      )
    ]),
  ]);

  if (collection === 'ellipse') {
    var toggleStyleData = _.get(propertiesDataById, '21.selectedProductOption');
    var toggleIsJMA = _.get(toggleStyleData, 'id') === 120 ? true : false;
  }

  var ellipseButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -5.75, y: -7.25}, [
      path({d: [
        {command: 'M', x: 0, y: 4.5},
        {command: 'C', x1: 0, y1: -1.5, x2: 9, y2: -1.5, x: 9, y: 4.5},
        {command: 'L', x: 9, y: 9.5},
        {command: 'C', x1: 9, y1: 15.5, x2: 0, y2: 15.5, x: 0, y: 9.5},
        {command: 'Z'}
      ], fill: mechanismFinish, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
      path ({d: [
        {command: 'M', x: 1, y: 4.5},
        {command: 'C', x1: 1, y1: -0.5, x2: 8, y2: -0.5, x: 8, y: 4.5},
        {command: 'L', x: 8, y: 9.5},
        {command: 'C', x1: 8, y1: 14.5, x2: 1, y2: 14.5, x: 1, y: 9.5},
        {command: 'Z'}
      ], fill: mechanismFinish, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 7.25, horizontalOffset: 5.75})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 7.25, horizontalOffset: 5.75, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
          )
        : []
      )
    ])
  ]);

  var ellipseToggle = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -5.75, y: -7.25}, [
      path({d: [
        {command: 'M', x: 0, y: 4.5},
        {command: 'C', x1: 0, y1: -1.5, x2: 9, y2: -1.5, x: 9, y: 4.5},
        {command: 'L', x: 9, y: 9.5},
        {command: 'C', x1: 9, y1: 15.5, x2: 0, y2: 15.5, x: 0, y: 9.5},
        {command: 'Z'}
      ], fill: mechanismFinish, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
      path ({d: [
        {command: 'M', x: 1, y: 4.5},
        {command: 'C', x1: 1, y1: -0.5, x2: 8, y2: -0.5, x: 8, y: 4.5},
        {command: 'L', x: 8, y: 9.5},
        {command: 'C', x1: 8, y1: 14.5, x2: 1, y2: 14.5, x: 1, y: 9.5},
        {command: 'Z'}
      ], fill: mechanismFinish, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
      ...(toggleIsJMA
        ? [path({d: [
          {command: 'M', x: 1, y: 3},
          {command: 'C', x1: 1, y1: -1, x2: 5, y2: -1, x: 5, y: 3},
          {command: 'C', x1: 5, y1: 7, x2: 1, y2: 7, x: 1, y: 3}
        ], fill: mechanismFinish, stroke: 'black', strokeWidth: 1, x: 3, y: 3})]
        : [circle({
          cx: 6,
          cy: 6,
          fill: mechanismFinish,
          strokeWidth: 1,
          stroke: 'black',
          r: 3,
        })]),
        ...(engravingsData.isEnabled
          ? (hasCustomEngravings
              ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 7.25, horizontalOffset: 5.75})
              : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 7.25, horizontalOffset: 5.75, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
            )
          : []
        )
    ])
  ]);

  var solarisButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -7, y: -7}, [
      circle({
        cx: 7,
        cy: 7,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 7
      }),
      group({x: 0.5, y: 0.5}, [
        circle({
          cx: 6.5,
          cy: 6.5,
          fill: mechanismFinish,
          strokeWidth: 0.5,
          stroke: 'black',
          r: 6.5,
        }),
      ]),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 7, horizontalOffset: 7})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 7, horizontalOffset: 7, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
          )
        : []
      )
    ])
  ]);

  var solarisToggle = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -7, y: -7}, [
      circle({
        cx: 7,
        cy: 7,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 7
      }),
      group({x: 0.5, y: 0.5}, [
        circle({
          cx: 6.5,
          cy: 6.5,
          fill: mechanismFinish,
          strokeWidth: 0.5,
          stroke: 'black',
          r: 6.5,
        }),
      ]),
      group({x: 4, y: 4}, [
        circle({
          cx: 3,
          cy: 3,
          fill: mechanismFinish,
          strokeWidth: 0.5,
          stroke: 'black',
          r: 3
        })
      ]),
      group({x: 5, y: 5}, [
        circle({
          cx: 2,
          cy: 2,
          fill: mechanismFinish,
          strokeWidth: 0.5,
          stroke: 'black',
          r: 2
        })
      ]),
      group({x: 4, y: 6}, [
        path({d: [
          {command: 'M', x: 2, y: 0},
          {command: 'C', x1: -0.5, y1: 0, x2: -0.5, y2: 2, x: 2, y: 2},
          {command: 'L', x: 4, y: 2},
          {command: 'C', x1: 6.5, y1: 2, x2: 6.5, y2: 0, x: 4, y: 0},
          {command: 'Z'}
        ], strokeWith: 1, stroke: 'black', fill: mechanismFinish})
      ]),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 7, horizontalOffset: 7})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 7, horizontalOffset: 7, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows})
          )
        : []
      )
    ])
  ]);

  var crestnetButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -6, y: -6}, [
      circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinish,
        strokeWidth: 1,
        stroke: 'black',
        r: 6,
      }),
      circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
        r: 5.5,
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 6, horizontalOffset: 6})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 6, horizontalOffset: 6, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows, ledIndicatorIsEnabled, ledPosition})
          )
        : []
      ),
      ...(ledIndicatorIsEnabled ? [
        group({x: 6, y: 6 + (ledPosition === 'above' ? -9 : 9)}, [
          circle({
            cx: 0,
            cy: 0,
            fill: 'black',
            strokeWidth: 0.1,
            stroke: 'black',
            r: 0.5
          })
        ])
      ] : [])
    ]),
  ]);

  var crestnetSquareButton = ({engravingsData, hasMechanismBelow, hasMechanismAbove}) => ([
    group({x: -6, y: -6}, [
      rect({
        width: 12,
        height: 12,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
      }),
      rect({
        x: 0.25,
        y: 0.25,
        width: 11.5,
        height: 11.5,
        fill: mechanismFinish,
        strokeWidth: 0.5,
        stroke: 'black',
      }),
      ...(engravingsData.isEnabled
        ? (hasCustomEngravings
            ? getCustomMeljacEngravingProductGraphicNodes({image, text, engravingsData, verticalOffset: 6, horizontalOffset: 6})
            : getEngraving({rect, engravingsData, image, ySpacing, verticalOffset: 6, horizontalOffset: 6, text, xSpacing, group, deps, dimension, hasMechanismBelow, hasMechanismAbove, plateFinish, backLightOpacity: '0.5', rectHeight, totalRows, ledIndicatorIsEnabled, ledPosition})
          )
        : []
      ),
    ]),
    ...(ledIndicatorIsEnabled ? [
      group({x: 0, y: 0 + (ledPosition === 'above' ? -9 : 9)}, [
        circle({
          cx: 0,
          cy: 0,
          fill: 'black',
          strokeWidth: 0.1,
          stroke: 'black',
          r: 0.5
        })
      ])
    ] : [])
  ]);

  _.forEach(layoutStringArray, (row, index) => {
    var hasMechanismAbove = layoutStringArray.length > 1 ? 1 : 0;
    var hasMechanismBelow = layoutStringArray.length > 1 ? 1 : 0;
    var justify = 0;

    if (row.length !== totalColumns) {
      if (justifyPosition === 'start') justify = 0;
      if (justifyPosition === 'center') justify = ((totalColumns * xSpacing) - (row.length * xSpacing)) / 2;
      if (justifyPosition === 'end') justify = (totalColumns * xSpacing) - (row.length * xSpacing);
    }

    if (index === 0) hasMechanismAbove = 0;
    if (index + 1 === layoutStringArray.length) hasMechanismBelow = 0;

    _.forEach(row, (string, mechanismIndex) => {
      var xPosition = xSpacing * mechanismIndex + justify;
      var yPosition;

      if (_.isArray(ySpacing)) {
        yPosition = index === 0 ? 0 : ySpacing[index - 1];
      }
      else {
        yPosition = buttonsYPosition;
      }

      if (collection === 'crestnet') {
        if (string === 'B') mechanisms.push(group({x: xPosition, y: yPosition}, crestnetButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
        if (string === 'S') mechanisms.push(group({x: xPosition, y: yPosition}, crestnetSquareButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
      }
      if (collection === 'classique') {
        if (string === 'B') mechanisms.push(group({x: xPosition, y: yPosition}, classiqueButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
        if (string === 'T') mechanisms.push(group({x: xPosition, y: yPosition}, classiqueToggle({engravingsData, hasMechanismBelow, hasMechanismAbove})));
      }
      if (collection === 'damier') {
        if (string === 'B') mechanisms.push(group({x: xPosition, y: yPosition}, damierCircleButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
        if (string === 'S') mechanisms.push(group({x: xPosition, y: yPosition}, damierSquareButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
      }
      if (collection === 'ellipse') {
        if (string === 'B') mechanisms.push(group({x: xPosition, y: yPosition}, ellipseButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
        if (string === 'T') mechanisms.push(group({x: xPosition, y: yPosition}, ellipseToggle({engravingsData, hasMechanismBelow, hasMechanismAbove})));
      }
      if (collection === 'solaris') {
        if (string === 'B') mechanisms.push(group({x: xPosition, y: yPosition}, solarisButton({engravingsData, hasMechanismBelow, hasMechanismAbove})));
        if (string === 'T') mechanisms.push(group({x: xPosition, y: yPosition}, solarisToggle({engravingsData, hasMechanismBelow, hasMechanismAbove})));
      }

      _.set(engravingsData, 'index', _.get(engravingsData, 'index') + 1);
    });

    buttonsYPosition = buttonsYPosition + ySpacing;
  });

  return mechanisms;
}

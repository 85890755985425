import React from 'react';

import { PureComponent } from 'react';
import { Text, Link, Label, TextInput, DocumentTitle, Loading } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';
import { accentColorForOrg } from '~/helpers/org-helper';

import K from '~/k';
import _ from '@symbolic/lodash';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';

import createIconWhite from '~/assets/create-icon-white.png';

class ProductPropertyGroupRow extends React.PureComponent {
  updateProductPropertyGroupRank = ({value, productPropertyGroup}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productPropertyGroups, 'rank'), {id: productPropertyGroup.id});
    var oldIds = _.map(_.sortBy(this.props.productPropertyGroups, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductPropertyGroups({updates});
    }
  };

  render() {
    var {productPropertyGroup, index} = this.props;

    var textCellStyle = {paddingHorizontal: K.spacing};

    return (
      <View
        dataSet={{'hover-background-double-gray': 1}}
        style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
      >
        <TextInput
          value={index + 1}
          onChange={({value})=> this.updateProductPropertyGroupRank({value, productPropertyGroup, index})}
          style={{backgroundColor: 'transparent', alignSelf: 'center', width: 75, paddingHorizontal: K.spacing, textAlign: 'center', opacity: 0.5}}
          selectTextOnFocus
        />
        <Link to={`/admin/product-property-groups/${productPropertyGroup.id}`} style={{paddingLeft: K.spacing, flexDirection: 'row', flex: 1, height: '100%', alignItems: 'center'}}>
          <Text style={{width: 75, opacity: 0.5, ...textCellStyle}}>{productPropertyGroup.id}</Text>
          <Text style={{flex: 1, ...textCellStyle}}>{productPropertyGroup.title}</Text>
        </Link>
      </View>
    );
  }
}

class AdminProductPropertyGroupPage extends PureComponent {
  state = {
    isLoading: true
  };

  async componentDidMount() {
    var productPropertyGroups = await api.get('productPropertyGroups', {where: {orgId: this.props.session.activeOrg.id}});

    await this.props.trackProductPropertyGroups({productPropertyGroups});

    setTimeout(() => this.setState({isLoading: false}));
  }

  createProductPropertyGroup = async () => {
    var productPropertyGroup = await api.create('productPropertyGroup', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Property Group',
      rank: _.max(_.map(this.props.productPropertyGroups, ({rank}) => rank)) + 1
    });

    this.props.trackProductPropertyGroups({productPropertyGroups: [productPropertyGroup]});

    //HINT need the setTimeout after trackProducts
    setTimeout(() => this.props.history.push(`/admin/product-property-groups/${productPropertyGroup.id}`));
  };

  render() {
    var {productPropertyGroups} = this.props;

    //TODO loop over orgs
    return (
      <DocumentTitle title={'Admin: Property Groups'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1, position: 'relative'}}>
            <SecondaryHeader
              title={'Property Groups'}
            ></SecondaryHeader>
            {this.state.isLoading ? (
              <Loading />
            ) : (
              <>
                <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: K.spacing, paddingBottom: K.spacing * 4}}>
                  <View style={{paddingLeft: K.spacing * 2, flexDirection: 'row', marginRight: K.spacing}}>
                    {_.map([
                      {title: 'Rank', style: {width: 75}},
                      {title: 'ID', style: {width: 75}},
                      {title: 'Title', style: {flex: 1}}
                    ], ({title, style}, h) => (
                      <View key={h} style={{...style, padding: K.spacing}}>
                        <Label>{title}</Label>
                      </View>
                    ))}
                  </View>
                  <View>
                    {_.map(_.sortBy(productPropertyGroups, ['rank', 'id']), (productPropertyGroup, index) => (
                      <ProductPropertyGroupRow
                        key={productPropertyGroup.id}
                        {...{productPropertyGroup, productPropertyGroups, index, ..._.pick(this.props, ['updateProductPropertyGroups'])}}
                      />
                    ))}
                  </View>
                  {/* TODO pagination */}
                </ScrollView>
                <TouchableOpacity
                  onPress={this.createProductPropertyGroup}
                  style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), width: 70, height: 70, borderRadius: 70, alignItems: 'center', 'justifyContent': 'center'}}
                >
                  <Image source={createIconWhite} style={{width: K.calc(35), height: K.calc(35)}}/>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state) => ({
    productPropertyGroups: _.get(state, 'resources.productPropertyGroups.byId')
  }),
  mapDispatch: {
    ..._.pick(resourceActions.productPropertyGroups, ['trackProductPropertyGroups', 'updateProductPropertyGroups'])
  }
})(AdminProductPropertyGroupPage);

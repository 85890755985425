import { PureComponent } from 'react';
import { View, ScrollView } from 'react-native';
import { Text, Button, Popup, DocumentTitle, Loading } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';

import K from '~/k';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import ProductProperty from '~/components/product-property';
import ProductPropertiesPopup from '~/components/popups/product-properties-popup';

import settingsIcon from '~/assets/settings-icon.png';

import React from 'react';
import _ from 'lodash';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

require(`ace-builds/src-noconflict/theme-github`); //eslint-disable-line
require(`ace-builds/src-noconflict/mode-javascript`); //eslint-disable-line
require(`ace-builds/src-noconflict/snippets/javascript`); //eslint-disable-line

class AdminProductPropertyGroupPage extends PureComponent {
  state = {
    productPropertiesPopupIsVisible: false,
    productPropertyGroupSettingsPopupIsVisible: false,
    image: null,
    uploading: false,
    uploadingWasSuccessful: false,
    uploadingHasFailed: false,
    isLoading: true
  };

  constructor(props) {
    super(props);
  }

  handleSettingsPopupChange = (value) => this.setState({productPropertyGroupSettingsPopupIsVisible: !value});

  async componentDidMount() {
    var productPropertyGroups = await api.get('productPropertyGroups', {where: {id: this.props.productPropertyGroupId, orgId: this.props.session.activeOrg.id}});
    var productProperties = await api.get('productProperties', {where: {orgId: this.props.session.activeOrg.id}});
    var productOptions = await api.get('productOptions', {where: {orgId: this.props.session.activeOrg.id}});

    await this.props.trackProductPropertyGroups({productPropertyGroups});
    await this.props.trackProductProperties({productProperties});
    await this.props.trackProductOptions({productOptions});

    setTimeout(() => this.setState({isLoading: false}));
  }

  deleteProductProperty = async () => {
    if (await confirm('Are you sure you want to delete this property group?')) {
      this.props.history.push('/admin/product-property-groups');

      setTimeout(() => {
        this.props.destroyProductPropertyGroup({id: this.props.productPropertyGroup.id});
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <Loading />
          </View>
        </View>
      );
    }
    else {
      var {productPropertiesById, productPropertyGroup} = this.props;

      var sortedProductProperties = _.chain(productPropertyGroup.properties)
        .map((_value, productPropertyId) => productPropertiesById[productPropertyId])
        .filter(productProperty => !!productProperty)
        .sortBy('rank')
        .value();

      return (
        <DocumentTitle title={`Admin: ${productPropertyGroup.title} - Product Property Group`}>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <AdminMenu activeOrg={this.props.session.activeOrg} />
            <View style={{flex: 1}}>
              <SecondaryHeader
                title={productPropertyGroup.title}
                titleIsEditable
                onTitleChange={({value}) => this.props.updateProductPropertyGroup({id: this.props.productPropertyGroup.id, props: {title: value}})}
                subTitle={`ID: ${productPropertyGroup.id}`}
                rightComponent={(
                  <View style={{alignItems: 'flex-end'}}>
                    <Button label='Publish' mode='dark' style={{width: 200, padding: K.spacing, borderRadius: 20}} />
                  </View>
                )}
                belowHeaderComponent={(
                  <View style={{marginTop: K.spacing * 2}}>
                    <Button
                      icon={settingsIcon}
                      onPress={() => this.handleSettingsPopupChange(this.state.productPropertGroupSettingsPopupIsVisible)}
                      style={{backgroundColor: 'transparent', ...K.defaultIconSize}}
                    />
                  </View>
                )}
              ></SecondaryHeader>
              <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, flex: 1}}>

                {!_.isEmpty(productPropertyGroup.properties) ? (
                  <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                    {_.map(sortedProductProperties, (productProperty) => (
                      <ProductProperty key={`property-${productProperty.id}`} {...{productProperty, mode: 'admin'}} />
                    ))}
                  </View>
                ) : (
                  <View style={{margin: K.spacing, paddingVertical: K.spacing * 4, paddingHorizontal: K.spacing, opacity: 0.5, textAlign: 'center', backgroundColor: K.colors.gray}}>
                    <Text>This group has no properties</Text>
                  </View>
                )}
                <View style={{paddingHorizontal: K.spacing, marginBottom: K.spacing * 2}}>
                  <Button label='Edit Properties' style={{width: 200, padding: K.spacing, marginBottom: K.spacing * 2, borderRadius: 20}} onPress={() => this.setState({productPropertiesPopupIsVisible: true})}/>
                </View>
              </ScrollView>
            </View>
            {this.state.productPropertiesPopupIsVisible && (
              <ProductPropertiesPopup
                onClose={() => this.setState({productPropertiesPopupIsVisible: false})}
                isGrouping
                {...{productPropertyGroup}}
              />
            )}
            {this.state.productPropertyGroupSettingsPopupIsVisible && (
              <Popup onClose={() => {
                this.handleSettingsPopupChange(this.state.productPropertyGroupSettingsPopupIsVisible);
              }}>
                <Button
                  label='Delete'
                  onPress={this.deleteProductProperty}
                  style={{backgroundColor: K.colors.deleteRed}}
                />
              </Popup>
            )}
          </View>
        </DocumentTitle>
      );
    }
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productPropertyGroupId = ownProps.match.params.productPropertyGroupId;
    var productPropertyGroup = _.get(state, `resources.productPropertyGroups.byId[${productPropertyGroupId}]`);
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionsById = state.resources.productOptions.byId;

    return {
      productPropertyGroupId,
      productPropertyGroup,
      productPropertiesById,
      productOptionsById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productPropertyGroups, ['trackProductPropertyGroups', 'updateProductPropertyGroup', 'destroyProductPropertyGroup']),
    ..._.pick(resourceActions.productProperties, ['trackProductProperties']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions'])
  }
})(AdminProductPropertyGroupPage);

import { PureComponent } from 'react';
import { Image, View, TouchableOpacity, ScrollView } from 'react-native';
import { DocumentTitle, Text, TextInput, Button, Tooltip, Loading } from '@symbolic/rn-lib';
import _ from '@symbolic/lodash';

import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';

import K from '~/k';

import UserGroupView from '~/components/user-group-view';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import sfApiNextRequest from '~/helpers/sf-api-next-request';

import closeIcon from '~/assets/x-icon.png';

class AdminUserGroupsPage extends PureComponent {
  state = {
    newUserGroup: '',
    newUserGroupEmail: '',
    isLoading: true
  };

  async componentDidMount() {
    var getGuestsDataResponse = await sfApiNextRequest({path: '/api/configurator/get-configurator-guests-data', body: {
      userId: this.props.session.user.id,
      orgId: await sessionStore.get('activeOrgId'),
    }});

    var userGroups = await api.get('userGroups', {where: {orgId: this.props.session.activeOrg.id}});

    await this.props.trackGuests({guests: _.get(getGuestsDataResponse, 'data.guests')});
    await this.props.trackUserGroups({userGroups},);

    this.setState({isLoading: false});
  }

  handleUserGroupInputChange = ({value}) => this.setState({newUserGroup: value});

  handleUserGroupNewUserInputChange = ({value}) => this.setState({newUserGroupEmail: value});

  addUserGroup = async () => {
    var userGroup = await api.create('userGroup', {
      orgId: this.props.session.activeOrg.id,
      title: this.state.newUserGroup,
      userIds: []
    });

    this.props.trackUserGroups({userGroups: [userGroup]});

    this.setState({newUserGroup: ''});
  };

  removeUserGroup = ({userGroup}) => {
    if (confirm('Are you sure you want to remove this user group?', '')) {
      this.props.destroyUserGroup({id: userGroup.id});
    }
  };

  render() {
    var {userGroups} = this.props;

    return (
      <DocumentTitle title={'Admin: User Groups'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader title='User Groups' />
            <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, marginBottom: K.spacing * 8, width: 500, minHeight: '100%'}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: K.margin * 2}}>
                <TextInput
                  blurOnSubmit
                  standardAutoheightStyles
                  value={this.state.newUserGroup}
                  onChange={this.handleUserGroupInputChange}
                  labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                  placeholder={'Enter User Group Title'}
                  style={{flexGrow: 1}}
                />
                <Button
                  label={'Add'}
                  dark
                  style={{marginLeft: K.spacing}}
                  onPress={() => this.addUserGroup()}
                />
              </View>
              {this.state.isLoading ? (
                <View style={{flex: 1}}>
                  <Loading />
                </View>
              ) : (
                <View style={{flexDirection: 'column'}}>
                  {_.map(_.orderBy(userGroups, 'id', 'desc'), userGroup => (
                    <View key={userGroup.id} style={{marginBottom: K.margin * 2, border: `1px solid ${K.colors.doubleGray}`, borderRadius: K.borderRadius, padding: K.spacing}}>
                      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{...K.fonts.label, color: 'black', paddingHorizontal: K.spacing, marginBottom: K.margin}}>{_.get(userGroup, 'title')}</Text>
                        <Tooltip text='Remove user group'>
                          <TouchableOpacity onPress={() => this.removeUserGroup({userGroup})}>
                            <Image source={closeIcon} style={{...K.defaultIconSize, marginRight: K.spacing}}/>
                          </TouchableOpacity>
                        </Tooltip>
                      </View>
                      <UserGroupView {...{userGroup}} />
                    </View>
                  ))}
                </View>
              )}
            </ScrollView>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var userGroups = _.get(state, 'resources.userGroups.byId');

    return {
      userGroups
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.userGroups, ['trackUserGroups', 'destroyUserGroup', 'updateUserGroup']),
    ..._.pick(resourceActions.guests, ['trackGuests'])
  }
})(AdminUserGroupsPage);

import { PureComponent } from 'react';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import { Label, DocumentTitle, LabelledView, PickerInput, Link, TextInput, Text, Loading } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { accentColorForOrg } from '~/helpers/org-helper';
import _ from '@symbolic/lodash';

import K from '~/k';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import AdminDataRow from '~/components/admin-data-row';

import createIconWhite from '~/assets/create-icon-white.png';
import AdminSection from '~/components/admin-section';

class ProductCategoryRow extends PureComponent {
  updateProductCategoryRank = ({value, productCategory}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productCategories, 'rank'), {id: productCategory.id});
    var oldIds = _.map(_.sortBy(this.props.productCategories, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductCategories({updates});
    }
  };

  render() {
    var {productCategory, index} = this.props;

    var textCellStyle = {paddingHorizontal: K.spacing};

    return (
      <View
        dataSet={{'hover-background-double-gray': 1}}
        style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
      >
        <TextInput
          value={index + 1}
          onChange={({value})=> this.updateProductCategoryRank({value, productCategory, index})}
          style={{backgroundColor: 'transparent', alignSelf: 'center', width: 75, paddingHorizontal: K.spacing, textAlign: 'center', opacity: 0.5}}
          selectTextOnFocus
        />
        <Link to={`/admin/product-categories/${productCategory.id}`} style={{paddingLeft: K.spacing, flexDirection: 'row', flex: 1, height: '100%', alignItems: 'center'}}>
          <Text style={{width: 75, opacity: 0.5, ...textCellStyle}}>{productCategory.id}</Text>
          <Text style={{flex: 1, ...textCellStyle}}>{productCategory.title}</Text>
        </Link>
      </View>
    );
  }
}

class AdminProductCategoriesPage extends PureComponent {
  state = {
    filterMode: 'active',
    isLoading: true
  };

  async componentDidMount() {
    var productCategories = await api.get('productCategories', {where: {orgId: this.props.session.activeOrg.id}});

    await this.props.trackProductCategories({productCategories});

    setTimeout(() => this.setState({isLoading: false}));
  }

  handleFilterModeChange = ({value}) => this.setState({filterMode: value});

  createProductCategory = async () => {
    var category = await api.create('productCategory', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Category',
      rank: _.max(_.map(this.props.productCategories, ({rank}) => rank)) + 1
    });

    this.props.trackProductCategories({productCategories: [category]});
  };

  render() {
    var {productCategories} = this.props;
    var {filterMode} = this.state;

    var productCategories = _.filter(productCategories, productCategory => {
      if (filterMode === 'active') return !productCategory.archived;
      if (filterMode === 'archived') return !!productCategory.archived;
      if (filterMode === 'all') return true;
    });

    return (
      <DocumentTitle title={'Admin: Product Categories'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader
              title={'Product Categories'}
              titleIsEditable
              rightComponent={(
                <View style={{right: 20}}>
                  <LabelledView label='Status' styles={{paddingTop: K.spacing}}>
                    <PickerInput
                      style={{height: K.inputHeight}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={[
                        {value: 'active', title: 'Active'},
                        {value: 'archived', title: 'Archived'},
                        {value: 'all', title: 'All'},
                      ]}
                      value={this.state.filterMode}
                      onChange={this.handleFilterModeChange}
                    />
                  </LabelledView>
                </View>
              )}
            />
            {this.state.isLoading ? (
              <Loading />
            ) : (
              <>
                <ScrollView style={{flex: 1}} contentContainerStyle={{addingTop: K.spacing, paddingBottom: K.spacing * 4}}>
                  <View style={{paddingLeft: K.spacing * 2, flexDirection: 'row', marginRight: K.spacing}}>
                    {_.map([
                      {title: 'Rank', style: {width: 75}},
                      {title: 'ID', style: {width: 75}},
                      {title: 'Title', style: {flex: 1}}
                    ], ({title, style}, h) => (
                      <View key={h}
                        style={{...style, padding: K.spacing}}
                      >
                        <Label>{title}</Label>
                      </View>
                    ))}
                  </View>
                  <View>
                    {_.map(_.sortBy(productCategories, ['rank', 'id']), (productCategory, index) => (
                      <ProductCategoryRow
                        key={productCategory.id}
                        {...{productCategory, productCategories, index, ..._.pick(this.props, ['updateProductCategories'])}}
                      />
                    ))}
                  </View>
                </ScrollView>
                <TouchableOpacity
                  onPress={() => this.createProductCategory()}
                  style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), width: 70, height: 70, borderRadius: 70, alignItems: 'center', 'justifyContent': 'center'}}
                >
                  <Image source={createIconWhite} style={{width: K.calc(35), height: K.calc(35)}}/>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state) => ({
    productCategories: _.get(state, 'resources.productCategories.byId')
  }),
  mapDispatch: {
    ..._.pick(resourceActions.productCategories, ['updateProductCategories', 'trackProductCategories']),
  }
})(AdminProductCategoriesPage);

import { Component } from 'react';
import { Text, Button, withKeyEvents, CopilotStepView, ScrollView } from '@symbolic/rn-lib';
import { View, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getNameFor, getIsSpatial } from '~/helpers/product-order-helper';

import ProjectInfoForm from '~/components/project-info-form';
import { accentColorForOrg } from '~/helpers/org-helper';
import HudElement from '~/components/hud-element';
import GetDisclaimer from '~/helpers/get-disclaimer';

import createIconWhite from '~/assets/create-icon-white.png';

import K from '~/k';

class AddButton extends Component {
  state = {
    isAdding: false
  };

  newProductInstance = () => {
    var {productOrder, productsById} = this.props;

    if (productOrder.productId || productOrder.type === 'arch') {
      var product = productsById[productOrder.productId];

      if (productOrder.type !== 'arch' && product.instanceTitleIsRequired) {
        this.props.showProductInstanceTitlePopup();
      }
      else {
        this.props.createProductInstance();
      }
    }
    else {
      this.props.showCreateInstancePopup();
    }
  };

  handleCreateButtonPress = () => {
    if (this.props.productInstances.length < 1 && this.props.productOrder.type !== 'arch') {
      if (this.props.session.activeOrg.role !== 'guest') {
        this.setState({isAdding: !this.state.isAdding});
      }
      else {
        this.newProductInstance();
      }
    }
    else {
      this.setState({isAdding: !this.state.isAdding});
    }
  };

  handleAddButtonOptionPress = ({key}) => {
    if (key === 'new') {
      this.newProductInstance();
    }
    else if (key === 'copy') {
      this.props.copyProductInstance();
    }
    else if (key === 'custom') {
      this.props.createCustomProductInstance();
    }
    else if (key === 'archWall') {
      this.props.addArchWall();
      // this.props.inLineMode();
    }
    else if (key === 'archElevation') {
      // props.inElevationMode
      this.props.addArchElevation();
    }
    else if (_.includes(['calicoMuralArea', 'calicoMuralTexture', 'calicoMuralPanel'], key)) {
      this.props.addCalicoMuralEntity({
        type: key,
        data: {
          shape: 'rect',
          position: {x: 0, y: 0},
          yRotation: 0,
          size: key === 'calicoMuralTexture' ? {width: 775, height: 175} : {width: 30, height: 30},
          ...(key === 'calicoMuralTexture' ? {muralProductOptionId: 990, substrate: 'standardCommercial'} : {}),
          ...(key === 'calicoMuralPanel' ? {isCriticalAlignmentPanel: false, isAtticStock: false, bleedLeft: 0.5, bleedRight: 0.5, bleedTop: 1, bleedBottom: 1} : {})
        }});
    }
    else if (key === 'calicoWallLabel') {
      this.props.addCalicoMuralEntity({
        type: key,
        data: {
          title: 'Untitled Wall',
          customViewPosition: {x: -50, y: -50},
          customViewSize: {width: 100, height: 100},
          labelPlanPosition: {x: 0, z: 0},
          viewStartPlanPosition: {x: 0, z: -20},
          indicateViewStartOnPlan: false
        }});
    }
    else if (key === 'calicoMuralSeam') {
      this.props.addCalicoLineEntity({type: 'calicoMuralSeam', stateKey: 'tentativeCalicoMuralSeam'});
    }
    else if (key === 'archCustomView') {
      this.props.addArchCustomView();
    }
    else if (key === 'calicoMismatchLine') {
      this.props.addCalicoLineEntity({type: 'calicoMismatchLine', stateKey: 'tentativeCalicoMismatchLine'});
    }
    else if (key === 'productCanvasText') {
      this.props.addProductCanvasText();
    }
    else if (key === 'productCanvasLine') {
      this.props.addProductCanvasLine();
    }
    else if (key === 'maderaDoor') {
      this.props.createProductInstance({productId: 104, properties: {position: {x: 0, y: 0}}});
    }
    else if (key === 'maderaCabinetFront') {
      this.props.createProductInstance({productId: 105, properties: {position: {x: 0, y: 0}}});
    }

    this.setState({isAdding: false});
  };

  handleKeyDown = event => {
    if (event.key === 'Escape') {
      this.setState({isAdding: false});
    }
  };

  handleProjectInfoFormCreateButtonPress = () => {
    var {productOrder} = this.props;
    var {activeOrg} = this.props.session;
    var {id: orgId} = activeOrg;

    if (productOrder.title === 'Untitled Order') {
      alert(`${getNameFor({productOrder, orgId, textToTransform: 'Order name'})} can not be "${getNameFor({productOrder, orgId, textToTransform: 'Untitled Order'})}", please enter a valid name.`);
    }
    else if (_.isEmpty(productOrder.title)) {
      alert(`A valid ${getNameFor({productOrder, orgId, textToTransform: 'order name'})} is required.`);
    }
    else {
      this.props.updateProductOrder({id: productOrder.id, props: {['promptedForProjectInfo']: 1}});
    }
  };

  render() {
    var {promptedForProjectInfo, isCreating, productOrder, totalPrice, productInstances, activeView} = this.props;
    var {activeOrg} = this.props.session;
    var {id: orgId} = activeOrg;
    var {isAdding} = this.state;

    var isArchitectural = _.get(activeOrg, 'appData.designEngine.isArchitectural');
    var showProjectInfoForm = !promptedForProjectInfo && !isArchitectural;

    var addButtonOptions = [];

    var isSpatial = getIsSpatial({productOrder, activeOrgId: orgId});

    if (!isSpatial) {
      addButtonOptions.push({title: 'New Item', key: 'new'});

      if (productInstances.length >= 1) {
        addButtonOptions.push({title: 'Copy/Duplicate', key: 'copy'});
      }

      if (activeOrg.role !== 'guest') {
        addButtonOptions.push({title: 'Custom Product', key: 'custom'});
      }
    }
    else {
      addButtonOptions.push({title: 'Viewport', key: 'archCustomView'});
    }

    if (productOrder.type === 'arch') {
      if (activeView?.viewKey === 'top') {
        addButtonOptions.push({title: 'Wall', key: 'archWall'});
      }
    }

    if (activeOrg.id === 1053 && activeView?.viewKey === 'custom') {
      if (productOrder.productCategoryId === 20) addButtonOptions.push({title: 'Door', key: 'maderaDoor'});
      if (productOrder.productCategoryId === 21) addButtonOptions.push({title: 'Cabinet Front', key: 'maderaCabinetFront'});
    }

    if (activeOrg.id === 1798 && activeView?.viewKey === 'custom') {
      addButtonOptions.push({title: 'Mismatch Line', key: 'calicoMismatchLine'});
      addButtonOptions.push({title: 'Elevation Area', key: 'calicoMuralArea'});
      addButtonOptions.push({title: 'Mural', key: 'calicoMuralTexture'});
      addButtonOptions.push({title: 'Seam', key: 'calicoMuralSeam'});
      addButtonOptions.push({title: 'Panel', key: 'calicoMuralPanel'});
      addButtonOptions.push({title: 'Wall Label', key: 'calicoWallLabel'});
      addButtonOptions.push({title: 'Note', key: 'productCanvasText'});
      addButtonOptions.push({title: 'Line', key: 'productCanvasLine'});
    }

    var hudElementPosition = {x: 'left', y: 'bottom'};

    if (isCreating || showProjectInfoForm) hudElementPosition = {x: 'center', y: 'center'};

    return (
      <HudElement x={hudElementPosition.x} y= {hudElementPosition.y}>
        {showProjectInfoForm ? (
          <View style={{right: '50%', alignItems: 'center'}}>
            <ScrollView style={{width: 500, height: '65vh', paddingBottom: K.spacing * 2, paddingHorizontal: K.spacing}}>
              <ProjectInfoForm {...{productOrder, totalPrice}}/>
            </ScrollView>
            <Button
              nativeID='OrderPageProjectInfoFormContinueButton'
              label={`${getNameFor({productOrder, orgId, textToTransform: 'Create order'})}`}
              dark
              onPress={this.handleProjectInfoFormCreateButtonPress}
              style={{marginTop: K.spacing, alignSelf: 'flex-end'}}
            />
          </View>
        ) : (
          <>
            <View style={{position: 'relative', width: 50, height: 50, marginBottom: isCreating ? -15 : K.spacing, marginLeft: isCreating ? -12 : '25%'}} dataSet={{growOnHover: 1}}>
              <CopilotStepView
                key={true}
                name='add button'
                order={6}
                text={getNameFor({productOrder, orgId, textToTransform: 'Click here to add products to your order.'})}
                style={{position: 'absolute', width: '100%', height: '100%'}}
              />
              <TouchableOpacity
                nativeID={'OrderPageAddButton'}
                onPress={() => this.handleCreateButtonPress()}
                style={{borderRadius: 75, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), ...(isAdding ? {borderTopLeftRadius: 0, borderTopRightRadius: 0} : {})}}
              >
                <Image
                  source={createIconWhite}
                  style={{width: K.calc(35), height: K.calc(35)}}
                />
              </TouchableOpacity>
              {isAdding && (
                <View style={{position: 'absolute', bottom: 50, left: 0}}>
                  {_.map(addButtonOptions, (addButtonOption, index) => (
                    <Button
                      nativeID={`OrderPageAddButtonOption-${addButtonOption.key}`}
                      dataSet={{fadeOnHover: 1}}
                      key={index}
                      onPress={() => this.handleAddButtonOptionPress({key: addButtonOption.key})}
                      style={{backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), height: 40, paddingHorizontal: K.spacing, alignItems: 'flex-end', width: K.isWeb ? 230 : 200, borderBottomColor: 'rgba(255, 255, 255, 0.2)', borderBottomWidth: 1, borderRadius: 0, ...(index === 0 && {borderTopLeftRadius: 10, borderTopRightRadius: 10}), ...(index === addButtonOptions.length - 1 && {borderBottomRightRadius: 10})}}
                    >
                      <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.calc(4), width: '100%'}}>
                        <Text style={{color: 'white', flex: 1}}>{addButtonOption.title}</Text>
                      </View>
                    </Button>
                  ))}
                </View>
              )}
            </View>
            {!isSpatial && (
              <View style={{width: 120, opacity: 0.5, marginBottom: K.spacing, marginLeft: isCreating ? -45 : -K.margin}}>
                {isCreating ? (
                  <View style={{marginTop: K.spacing * 2}}>
                    <Text style={{textAlign: 'center'}}>Click + to add your first product</Text>
                    {activeOrg.id === 850 && _.includes([1, 2, 5, 10, 11, 6, 7, 8, 15, 9, 87, 72, 89], productOrder.productId) && (
                      <View style={{marginTop: K.spacing * 2, width: 360, marginLeft: -110}}><GetDisclaimer orgId={this.props.session.activeOrg.id} disclaimerType={'backBoxDisclaimer'} /></View>
                    )}
                  </View>
                ) : (
                  <Text style={{textAlign: 'center', marginTop: 0}}>Add product</Text>
                )}
              </View>
            )}
          </>
        )}
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productInstances = _.filter(state.resources.productInstances.byId, {productOrderId});
    var productsById = state.resources.products.byId;

    return {
      productInstances,
      productsById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder']),
  }
})(withKeyEvents(AddButton));

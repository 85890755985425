import { Component, Fragment } from 'react';
import { View, ScrollView, Image } from 'react-native';
import { withRouter } from 'react-router-native';
import { Text as SFText, Button, DocumentTitle, PickerInput, Loading, Tooltip } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { setActiveView } from '~/redux/active-view';
import { api, event as LibEvent, products } from '@symbolic/lib';
import { invoiceModes } from '~/helpers/ui-modes';
import { downloadCsv } from '~/helpers/csv-helper';

import { referenceCodeFor } from '~/helpers/reference-code-for';
import { getProductInstancesWithData, filterInstancePropertiesFor, formatAmount } from '~/helpers/product-order-helper';
import { getInvoiceInlinePropertiesString } from '~/helpers/get-invoice-inline-properties-string';
import sfApiNextRequest from '~/helpers/sf-api-next-request';

import moment from 'moment';
import K from '~/k';
import dinero from 'dinero.js';
import queryString from 'query-string';
import style from '~/helpers/document-style-tag';
import getTextHeight from '~/helpers/get-text-height';
import getPricingIsHidden from '~/helpers/get-pricing-is-hidden/get-pricing-is-hidden';
import stringifyCssRule from 'stringify-css-rule';

import Page from '~/components/page';
import PageHeader from '~/components/page-header';
import OrderCostsTable from '~/components/order-costs-table';
import GetDisclaimer from '~/helpers/get-disclaimer';

import downloadIconBlack from '~/assets/download-icon-black.png';
import pdfIconBlack from '~/assets/pdf-icon-black.png';
import printIcon from '~/assets/print-icon.png';
import _ from 'lodash';

var Text = ({...props}) => {
  return (
    <SFText {...props} style={{...props.style, letterSpacing: 0}}/>
  );
};

var {
  getCostsForOrder,
  getPaymentAmountForOrder,
  getActiveProductOrderStatus,
  priceFor,
  productPricingRuleVersionFor,
  getFilteredProductOrderStatuses,
  getProductOrderStatusesFor,
  getArrayQuantityForArrayProductProperty
} = products;

var {getUsedEngravingCount} = products.meljac;

class Table extends Component {
  render() {
    return (
      <View style={[!this.props.fixedWidth ? {flex: 1} : null, {alignSelf: 'flex-start', ...this.props.style}]}>
        {this.props.children}
      </View>
    );
  }
}

class Label extends Component {
  render() {
    const {label, labelStyles, dataRow, fixedWidth, ...props} = this.props;

    return (
      <View style={[!fixedWidth ? {flex: 1} : null, {...labelStyles}]} {...props}>
        <Text style={{color: K.colors.black, fontWeight: dataRow ? 'none' : 'bold'}}>{label}</Text>
      </View>
    );
  }
}

class Value extends Component {
  render() {
    const {value, valueStyles, isHeaderRow, ...props} = this.props;

    return (
      <View style={{flex: 1, ...valueStyles}} {...props}>
        <Text style={{color: K.colors.black, fontWeight: isHeaderRow ? 'bold' : 'none'}}>{value}</Text>
      </View>
    );
  }
}

class Row extends Component {
  render() {
    const {label, value, isHeaderRow, dataRow, valueStyles, fixedWidthLabel, labelStyles, labels, values, rowStyles} = this.props;

    return (
      <View style={{flexDirection: 'row', marginBottom: K.margin, ...rowStyles}}>
        {!label && labels && _.size(labels) ? _.map(labels, (value, key) => <Label {...{label: value, labelStyles: labelStyles?.[key], dataRow, key, fixedWidth: fixedWidthLabel}} />) : label ? <Label {...{label, labelStyles, dataRow, fixedWidth: fixedWidthLabel}} /> : null}
        {!labels && !values && <View style={{flex: 0.2}}/>}
        {!value && values && _.size(values) ? _.map(values, (item, key) => <Value {...{value: item, valueStyles, isHeaderRow, key}}/>) : value ? <Value {...{value, valueStyles, isHeaderRow}}/> : null}
      </View>
    );
  }
}

class InvoicePage extends Component {
  state = {resourceStatus: 'unloaded'};

  pricingData = null;

  async componentDidMount() {
    let productInstances;

    var {productOrderOrgSpecificId} = this.props;
    var {shareToken, shareableOrderId} = queryString.parse(window.location.search);
    var orgId = await sessionStore.get('activeOrgId');

    var getOrderDataResponse = await sfApiNextRequest({path: '/api/configurator/get-configurator-order-data', body: {
      userId: this.props.session.user.id,
      orgId, productOrderOrgSpecificId,
      shareToken, shareableOrderId
    }});

    await this.props.trackProductOrders({productOrders: _.get(getOrderDataResponse, 'data.productOrders'), reset: true});
    await this.props.trackProducts({products: _.get(getOrderDataResponse, 'data.products'), reset: true});
    await this.props.trackProductInstances({productInstances: _.get(getOrderDataResponse, 'data.productInstances'), reset: true});
    await this.props.trackProductPricingRules({productPricingRules: _.get(getOrderDataResponse, 'data.productPricingRules'), reset: true});
    await this.props.trackProductProperties({productProperties: _.get(getOrderDataResponse, 'data.productProperties'), reset: true});
    await this.props.trackProductOptions({productOptions: _.get(getOrderDataResponse, 'data.productOptions'), reset: true});
    await this.props.trackProductOptionClassifiers({productOptionClassifiers: _.get(getOrderDataResponse, 'data.productOptionClassifiers'), reset: true});
    await this.props.trackProductPropertyGroups({productPropertyGroups: _.get(getOrderDataResponse, 'data.productPropertyGroups'), reset: true});
    await this.props.trackProductRules({productRules: _.get(getOrderDataResponse, 'data.productRules'), reset: true});

    setTimeout(() => {
      var productOrder = _.get(getOrderDataResponse, 'data.productOrders[0]', null);

      if (productOrder) {
        productInstances = _.get(getOrderDataResponse, 'data.productInstances', []);
      }

      if (_.size(productInstances) > 0) {
        const getInstancePrices = (productInstance) => {
          const unitPrice = dinero({amount: this.calculatePrice({productInstance})});

          return {
            unitPrice: formatAmount(unitPrice.getAmount()),
            subTotal: formatAmount(unitPrice.multiply(productInstance.quantity).getAmount())
          };
        };

        this.pricingData = {
          ..._.mapValues(_.keyBy(productInstances, (productInstance) => productInstance.id), getInstancePrices),
        };
      }

      this.setState({resourceStatus: 'loaded'});
    });

    window.addEventListener('keydown', this.handleKeyboardEvents);

    document.body.style = `
    color-adjust: exact;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact; `;

    if (_.includes(this.props.location.search, '?mode=invoice')) {
      this.handleActveViewDataChange({key: 'invoiceMode', value: invoiceModes.INVOICE});
    }
    else {
      this.handleActveViewDataChange({key: 'invoiceMode', value: invoiceModes[0]});
    }
  }

  handleKeyboardEvents = (event) => {
    if (LibEvent.keyPressed(event, 'ctrlcmd') && event.key === 'p') {
      event.preventDefault();

      this.print();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardEvents);
  }

  handleActveViewDataChange = ({key, value}) => this.props.setActiveView({data: {[key]: value}});

  getProductInstanceRowLabels = ({productInstance, index, filteredColumns}) => {
    return _.map(filteredColumns, item => item.value({productInstance, product: productInstance.product, key: _.get(productInstance, 'id'), listItemNumber: index + 1}))
  }

  downloadCSV = ({filteredColumns, productInstances, invoiceMode}) => {
    var productOrder = this.props.productOrder;
    var filename = `#${productOrder.orgSpecificId} - ${productOrder.title} - ${moment().format('YYMMDD')} - ${invoiceMode === 'packingSlip' ? 'packing slip' : 'quote'}`;
    var csv = [];

    var headers = _.map(filteredColumns, 'label');

    csv.push(headers);

    _.forEach(productInstances, (productInstance, index) => {
      csv.push(this.getProductInstanceRowLabels({productInstance, index, filteredColumns}));
    })

    downloadCsv({csv, filename});
  }

  downloadPDF = () => {
    const productOrder = this.props.productOrder;
    const element = document.querySelector('div[data-invoice="1"]');

    this.props.setActiveView({data: {isPrinting: true}});

    setTimeout(async () => {
      var domain = process.env.NODE_ENV === 'production' ? 'https://api.symbolicframeworks.com' : 'http://localhost:3301';
      var url = `${domain}/download-pdf`;

      var styleString = _.join(_.map(document.styleSheets, styleSheet => {
        return styleSheet.cssRules
          ? Array.from(styleSheet.cssRules)
            .map(rule => stringifyCssRule(rule))
            .join('\n')
          : '';
      }), '\n');

      var html = '<html><head><style> @font-face { font-family: \'Arial\'; src: url(\'https://symbolic-public.s3.amazonaws.com/fonts/Arial.ttf\')}</style></head><body>' + element.outerHTML + '<style>' + styleString + '</style></body></html>';
      var token = await sessionStore.getToken();
      var options = JSON.stringify({landscape: true, format: 'Letter'});

      var filenameSuffix = {
        client: 'Quote',
        packingSlip: 'Packing Slip',
        manufacturing: 'Factory',
        invoice: 'Invoice'
      }[this.props.invoiceMode || 'client'];

      var filename = `#${productOrder.orgSpecificId} - ${productOrder.title} - ${moment().format('YYMMDD')} - ${filenameSuffix}`;

      setTimeout(() => {
        document.querySelector('form[name="generatePdfForm"]').action = url;
        document.querySelector('input[name="html"]').value = html;
        document.querySelector('input[name="token"]').value = token;
        document.querySelector('input[name="options"]').value = options;
        document.querySelector('input[name="filename"]').value = filename;
      });

      setTimeout(() => document.querySelector('form[name="generatePdfForm"]').submit());

      this.props.setActiveView({data: {isPrinting: false}});
    });
  };

  print = () => {
    this.props.setActiveView({data: {isPrinting: true}});

    document.querySelector('html').style.height = 'auto';
    document.querySelector('body').style.height = 'auto';

    var landscapeModeCss = '@media print{ @page{ size: letter landscape; margin: 0px;}}';
    style.textContent = style.textContent + landscapeModeCss;

    setTimeout(() => {
      window.print();

      style.textContent = _.replace(style.textContent, landscapeModeCss, '');

      document.querySelector('html').style.height = '100%';
      document.querySelector('body').style.height = '100%';

      this.props.setActiveView({data: {isPrinting: false}});
    });
  };

  calculatePrice = ({productInstance}) => {
    const {productsById, productPricingRulesById, productOptionClassifiersById, productOptionsById, productPropertiesById, productRulesById, productInstancesById, productOrder} = this.props;
    const {expressions} = productPricingRuleVersionFor({productInstance, productOrder}, {productsById, productPricingRulesById});
    const product = productsById[productInstance.productId];

    const price = priceFor(expressions, {productInstance, shouldRoundPrice: product.shouldRoundPrice, shouldApplyQuantity: false}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, productOrder});

    return price;
  };

  get deliveryAddressString() {
    var {deliveryAddress} = this.props.productOrder;
    var {streetAddress, streetAddress2, city, state, zip, country} = deliveryAddress;

    country = !_.isEmpty(country) ? country : 'US';

    var deliveryAddressString = `${streetAddress}`;

    if (streetAddress2) deliveryAddressString += `\n${streetAddress2}`;

    deliveryAddressString += `\n${city}, ${state} ${zip}\n${country}`;

    return _.trim(deliveryAddressString);
  }

  render() {
    var {productInstancesById, productsById, productOrder, productPropertiesById, productOptionsById, hideInvoiceDiscount, invoiceMode, isPrinting, productRulesById, productPricingRulesById, productOptionClassifiersById, activeOrgId} = this.props;
    var {session} = this.props;
    var {activeOrg} = session;
    var {role} = activeOrg;

    if (this.state.resourceStatus !== 'loaded') return <Loading />;

    var pricingIsHidden = getPricingIsHidden(this.props);

    var productInstances = getProductInstancesWithData({productInstances: productInstancesById}, {productsById, productRulesById, productPropertiesById, productOptionsById});

    productInstances = _.sortBy(productInstances, 'rank');

    if (!productOrder || !productInstances.length) return <Loading />;

    var productIsKeypad = _.includes([1, 2, 5, 11, 10], productInstances[0].productId);

    var invoiceModeConfig = {
      shouldHidePrices: invoiceMode === invoiceModes.PACKINGSLIP || invoiceMode === invoiceModes.FACTORY,
      shouldHideMNApartNumbers: invoiceMode === invoiceModes.FACTORY || invoiceMode === invoiceModes.INVOICE,
      shouldHideFactoryReference: invoiceMode !== invoiceModes.FACTORY,
      shouldHideNumberOfEngravings: !productIsKeypad || invoiceMode === invoiceModes.INVOICE
    };

    var defaultProductInstance = _.find(productInstances, ({productId}) => productId !== -1) || _.get(productInstances, '0');

    var sortedPropertiesDataForAllProductInstancesMerged = () => {
      var allSortedPropertiesData = _.flatMap(productInstances, ({sortedPropertiesData}) => sortedPropertiesData);

      return _.uniqBy(allSortedPropertiesData, 'productProperty.id');
    };

    var orderLevelProperties = filterInstancePropertiesFor({
      productInstance: {...{sortedPropertiesData: sortedPropertiesDataForAllProductInstancesMerged()}}
    }, {
      isOrderLevel: 1,
      type: 'singleSelect'
    });

    orderLevelProperties = _.filter(orderLevelProperties, (property) => property.productProperty.invoiceVisibility !== 'none');

    if (productOrder.productId) {
      var productOrderProduct = _.get(productsById, productOrder.productId);
      var productOrderProductTitle = _.get(productOrderProduct, 'title');

      orderLevelProperties = [
        {productProperty: {title: 'Collection'}, selectedProductOption: {title: productOrderProductTitle}},
        ...orderLevelProperties
      ];
    }

    var invoiceColumnProperties = filterInstancePropertiesFor({productInstance: defaultProductInstance}, {invoiceVisibility: 'column', type: 'singleSelect'});
    var invoiceThumbnailProperties = filterInstancePropertiesFor({productInstance: defaultProductInstance}, {showThumbnailsOnInvoice: 1, type: 'singleSelect'});

    var columnWidths = {
      quantity: 30,
      unitPrice: 80,
      subtotal: 80,
      sku: 90,
      numberOfEngravings: 80,
      standard: invoiceColumnProperties.length ? Math.min(120, 480 / invoiceColumnProperties.length) : 120
    };

    var getColumnProperties = () => {
      var data = {};

      if (invoiceMode !== invoiceModes.INVOICE) {
        _.size(invoiceColumnProperties) && _.forEach(invoiceColumnProperties, (propertyData) => {
          var property = _.get(propertyData, 'productProperty');

          data[_.get(property, 'title')] = {
            label: _.get(property, 'title'),
            value: ({productInstance}) => _.get(productInstance, `propertiesDataById.${_.get(property, 'id')}.selectedProductOption.title`, ' '),
            style: {width: columnWidths.standard, paddingRight: 5},
            shouldHide: false
          };
        });
      }

      return data;
    };

    var pageSize = {width: 1056, height: 816};

    var margin = {width: K.spacing * 3, height: K.spacing * 3 + K.spacing * 3};

    //HINT: Actual page header's height = 50, margin bottom for that header = 30
    var headerHeight = 50 + 30;
    var contentSize = {width: pageSize.width - (margin.width * 2), height: pageSize.height - (margin.height * 2) - headerHeight};

    var getProductName = ({productInstance, product, listItemNumber}) => {
      var productTitle = '';

      if (productInstances.length > 1) productTitle = `${listItemNumber}. `;
      if (productOrder.productId) productTitle += productInstance.title || product.title;
      if (!productOrder.productId) productTitle += (productInstance.title ? `${productInstance.title} - ` : '') + product.title;

      if (productInstance.productId !== -1 && invoiceMode !== invoiceModes.INVOICE) {
        var invoiceInlineProperties = filterInstancePropertiesFor({productInstance}, {invoiceVisibility: 'inline', isOrderLevel: 0, type: 'singleSelect'});

        productTitle += (_.size(_.get(product, 'associations.productProperties')) === 0 && product.description ? `\n${product.description}` : '')
        + '\n\n'
        + getInvoiceInlinePropertiesString({invoiceInlineProperties, productInstance});
      }

      return productTitle;
    };

    var productsTableColumns = {
      product: {
        label: 'Product',
        value: getProductName,
        style: {paddingRight: 10},
        shouldHide: false
      },
      ...getColumnProperties(),
      factoryReference: {
        label: 'Factory Reference',
        value: ({productInstance, product}) => {
          if (productInstance.productId === -1) {
            return '';
          }
          else {
            return referenceCodeFor({productInstance, type: 'factory', activeOrgId}, {product, productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});
          }
        },
        style: {width: columnWidths.sku},
        shouldHide: invoiceModeConfig.shouldHideFactoryReference
      },
      numberOfEngravings: {
        label: 'Number of Engravings',
        value: ({productInstance}) => {
          var isCustomProduct = productInstance.productId === -1;
          var engravingsAreDisabled = _.get(productInstance, 'properties.24.optionId') === 133;

          if (isCustomProduct || engravingsAreDisabled) {
            return '';
          }
          else {
            var productProperty = _.get(productPropertiesById, '27');
            var arrayLength = getArrayQuantityForArrayProductProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById, productPropertiesById});

            var {usedEngravingCount} = getUsedEngravingCount({productInstance, productPropertyId: 27, productOptionsById, productsById, productRulesById, productPropertiesById, productInstancesById, arrayLength, productProperty});

            return usedEngravingCount;
          }
        },
        style: {width: columnWidths.numberOfEngravings},
        shouldHide: invoiceModeConfig.shouldHideNumberOfEngravings
      },
      sku: {
        label: 'SKU',
        value: ({productInstance, product}) => {
          if (productInstance.productId === -1) {
            return 'Custom Product';
          }
          else {
            return referenceCodeFor({productInstance, type: 'catalog', activeOrgId}, {product, productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});
          }
        },
        style: {width: columnWidths.sku},
        shouldHide: invoiceModeConfig.shouldHideMNApartNumbers
      },
      qty: {
        label: 'Qty',
        value: ({productInstance}) => `${productInstance?.quantity}`,
        style: {width: columnWidths.quantity, alignItems: 'flex-end'},
        shouldHide: false
      },
      ...(!pricingIsHidden ? {
        unitPrice: {
          label: 'List Price',
          value: ({key}) => this.pricingData?.[key]?.unitPrice,
          style: {width: columnWidths.unitPrice, alignItems: 'flex-end'},
          shouldHide: invoiceModeConfig.shouldHidePrices
        },
        subtotal: {
          label: 'Subtotal',
          value: ({key}) => this.pricingData?.[key]?.subTotal,
          style: {width: columnWidths.subtotal, alignItems: 'flex-end'},
          shouldHide: invoiceModeConfig.shouldHidePrices
        }
      } : {})
    };

    var filteredColumns = _.filter(productsTableColumns, item => !item.shouldHide);

    var labelStyles = _.map(filteredColumns, item => item.style);

    labelStyles[0].width = contentSize.width - _.sum(_.map(_.slice(labelStyles, 1), 'width')) - K.margin * 2;

    var pages = [];
    var activePage;
    var productInstancesCount = 0;

    var addPage = () => {
      activePage = {contentHeight: 0, children: []};

      pages.push(activePage);
    };

    var appendToPage = ({contentHeight}, jsx) => {
      if ((activePage.contentHeight + contentHeight) > contentSize.height) addPage();

      activePage.children.push(jsx);
      activePage.contentHeight += contentHeight;
    };

    var appendDynamicTableToPage = ({contentHeight}, jsx, header, headerHeight) => {
      if (productInstancesCount === 0) {
        activePage.children.push(header);
        activePage.contentHeight += headerHeight;
      }

      if ((activePage.contentHeight + contentHeight) > contentSize.height) {
        addPage();
        activePage.children.push(header);
        activePage.contentHeight += headerHeight;
      }

      activePage.children.push(jsx);
      activePage.contentHeight += contentHeight;
      productInstancesCount += 1;
    };

    addPage();

    //metadata + separator
    var orderLevelPropertiesHeight = (_.size(orderLevelProperties) * (K.margin * 2)) + (_.size(orderLevelProperties) * 15);

    if (invoiceMode === invoiceModes.INVOICE) {
      appendToPage({contentHeight: 45}, (
        <View style={{marginBottom: K.spacing * 2, alignItems: 'flex-end'}}>
          <Text style={{...K.fonts.pageHeader, color: K.colors.overlay, fontSize: K.calcFont(28)}}>INVOICE</Text>
          <Text style={{...K.fonts.label, textTransform: 'none'}}>Order #{productOrder.orgSpecificId}</Text>
        </View>
      ));
    }

    appendToPage({contentHeight: 160}, (<>
      <View style={{flexDirection: 'row'}}>
        <Table style={{width: contentSize.width * 0.4}} fixedWidth>
          <Row label='Quote Name' value={productOrder.title}/>
          {productOrder.projectName && (
            <Row label='Project Name' value={productOrder.projectName}/>
          )}
          {productOrder.purchaseOrderNumber && (
            <Row label='P.O. Number' value={productOrder.purchaseOrderNumber}/>
          )}
          {productOrder.estimateNumber && (
            <Row label='Estimate Number' value={productOrder.estimateNumber}/>
          )}
          <Row label='Company' value={productOrder.firmName}/>
          <Row label='Contact' value={productOrder.contactName}/>
          <Row label='Phone Number' value={productOrder.phone}/>
          <Row label='Shipping Address' value={this.deliveryAddressString}/>
          {productOrder.trackingNumber && (
            <Row label='Tracking Number' value={productOrder.trackingNumber}/>
          )}
        </Table>
        <View style={{width: contentSize.width * 0.2}}/>
        {invoiceMode !== invoiceModes.INVOICE && (
          <Table style={{width: contentSize.width * 0.4}} fixedWidth>
            {_.map(orderLevelProperties, (propertyData, key) => <Row key={key} label={_.get(propertyData, 'productProperty.title')} value={_.get(propertyData, 'selectedProductOption.title')}/>)}
          </Table>
        )}
      </View>
    </>));

    appendToPage({contentHeight: 30}, (
      <View style={{height: 30}}/>
    ));

    //primary note
    if (productOrder.primaryNote) {
      var primaryNoteHeight = getTextHeight({width: contentSize.width * 0.4, text: productOrder.primaryNote});

      appendToPage({contentHeight: Math.max(primaryNoteHeight, orderLevelPropertiesHeight) + 30}, (<>
        <View style={{flexDirection: 'row', marginBottom: 30}}>
          <Text style={{opacity: 0.6}}>{productOrder.primaryNote}</Text>
        </View>
      </>));
    }

    _.forEach(productInstances, (productInstance, index) => {
      var product = productsById[productInstance.productId];

      var row = <>
        <Row
          key={productInstance.id}
          labels={this.getProductInstanceRowLabels({productInstance, index, filteredColumns})}
          dataRow
          fixedWidthLabel
          {...{labelStyles}}
        />
        {productInstance.notes && (
          <View style={{marginBottom: K.calc(5), opacity: 0.8}}>
            <Row
              key={productInstance.id}
              labels={[`${productInstance.notes}`]}
              dataRow
              {...{labelStyles}}
            />
          </View>
        )}
      </>
      ;

      var productInstanceRow = {
        jsx: (_.size(invoiceThumbnailProperties) ?
          (<>
            {<View style={{height: K.calc(1), marginBottom: K.calc(5), backgroundColor: K.colors.doubleGray, width: '100%'}}/>}
            <View style={{flexDirection: 'row'}}>
              {_.map(invoiceThumbnailProperties, (property) => <Image source={{uri: _.get(property, 'selectedProductOption.thumbnailUrl')}} style={{width: 38, height: 38, borderRadius: 19, borderWidth: 1, marginRight: K.spacing}}/>)}
              {row}
            </View>
          </>
          )
          : (
            <>
              {<View style={{height: K.calc(1), marginBottom: K.calc(5), backgroundColor: K.colors.doubleGray, width: '100%'}}/>}
              {row}
            </>
          )
        ),
        contentHeight: _.max(_.map(filteredColumns, (filteredColumn, columnIndex) => {
          var textHeight = getTextHeight({
            text: filteredColumn.value({productInstance, product, key: _.get(productInstance, 'id'), listItemNumber: index + 1}),
            width: labelStyles[columnIndex].width
          }) + (K.margin * 2);

          return textHeight;
        })) + (productInstance.notes ? getTextHeight({text: productInstance.notes, width: 800}) : 0)
      };

      appendDynamicTableToPage({contentHeight: _.get(productInstanceRow, 'contentHeight')}, productInstanceRow.jsx,
        (_.size(invoiceThumbnailProperties) ?
          <View style={{flexDirection: 'row'}}>
            <View style={{width: 38, height: 38, borderRadius: 19, marginRight: K.spacing}}></View>
            <Row
              key={index}
              labels={_.map(filteredColumns, item => item.label)}
              labelStyles={labelStyles}
              fixedWidthLabel
              isHeaderRow
            />
          </View>
          : (
            <Row
              key={index}
              labels={_.map(filteredColumns, item => item.label)}
              labelStyles={labelStyles}
              fixedWidthLabel
              isHeaderRow
            />
          )
        ), 30);
    });

    if (invoiceMode !== invoiceModes.PACKINGSLIP) {
      var secondaryNoteHeight = getTextHeight({width: contentSize.width * 0.45, text: productOrder.primaryNote});

      var orderCosts = getCostsForOrder({productOrder, productInstances, includeDiscount: !hideInvoiceDiscount}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, activeOrg});

      var productOrderStatuses = getProductOrderStatusesFor({productOrder, productOrderStatuses: getFilteredProductOrderStatuses({productOrderStatuses: _.get(session, 'activeOrg.appData.designEngine.productOrderStatuses'), userRole: role})});
      var statusData = productOrder.statusData;
      var activeProductOrderStatus = getActiveProductOrderStatus({productOrderStatuses, statusData});
      var {paymentAmount, upchargePercentage} = getPaymentAmountForOrder({productOrder, orderCosts}, {productOrderStatuses, activeProductOrderStatus, activeOrg});

      var productOrderHasDiscount = _.get(productOrder, 'discountPercentage') !== 0 || _.get(productOrder, 'discountFlatAmount') !== 0 ;
      var showToggleDiscountButton = _.size(orderCosts) && !pricingIsHidden && productOrderHasDiscount;

      if (activeOrg.id === 850) {
        var isSampleOrder = _.includes([6, 7], productOrder.productCategoryId);

        if (isSampleOrder) showToggleDiscountButton = false;
      }

      // - move to PaymentStatusesForOrder({productOrder}) helper?
      var statusDataWithTypes = _.map(statusData, (value, key) => {
        var statusType = _.get(_.find(productOrderStatuses, {key}), 'type');

        return {...value, type: statusType, key};
      });

      var completedPaymentStatuses = _.filter(statusDataWithTypes, {type: 'payment'});
      var lastCompletedPaymentStatus = _.sortBy(completedPaymentStatuses, 'date').pop();
      //end helper

      // var lastCompletedPaymentStatusUpchargeAmountInCents = _.get(lastCompletedPaymentStatus, 'upchargeAmountInCents');
      // var lastCompletedPaymentIncludedUpcharge = !!lastCompletedPaymentStatusUpchargeAmountInCents && lastCompletedPaymentStatusUpchargeAmountInCents > 0;

      var completedPaymentStatusesWithPaymentAmount = _.filter(completedPaymentStatuses, (paymentStatus) => {
        return _.get(paymentStatus, 'paymentAmountInCents', 0) > 0;
      });

      var financialsHeight = 110 + (completedPaymentStatusesWithPaymentAmount.length * 40);

      appendToPage({contentHeight: Math.max(secondaryNoteHeight, financialsHeight)}, (<>
        <View style={{height: K.calc(1), marginTop: K.calc(20), marginBottom: K.calc(30), backgroundColor: K.colors.doubleGray, width: '100%'}}/>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <View style={{width: contentSize.width * 0.45}}>
            <Text style={{opacity: 0.6}}>
              {productOrder.secondaryNote}
            </Text>
          </View>
          <View style={{width: contentSize.width * 0.25}} />
          {activeOrg.id === 850 && _.includes([1, 2, 5, 10, 11, 6, 7, 8, 15, 9, 87, 72, 89], productOrder.productId) && invoiceMode !== invoiceModes.INVOICE && (
            <View style={{position: 'absolute', left: 0, paddingTop: K.spacing, width: 350}}><GetDisclaimer orgId={this.props.productOrder.orgId} disclaimerType={'backBoxDisclaimer'} /></View>
          )}
          {!pricingIsHidden && (
            <OrderCostsTable
              hideInvoiceDiscount={hideInvoiceDiscount}
              showAmountPaid={invoiceMode === invoiceModes.INVOICE}
              showAmountDue={invoiceMode === invoiceModes.INVOICE}
              showPayments={completedPaymentStatuses.length > 0 && invoiceMode !== invoiceModes.FACTORY}
              styles={{
                outerView: {width: contentSize.width * 0.25},
                row: {marginBottom: K.margin},
                title: {color: K.colors.black, fontWeight: 'bold'}, value: {color: K.colors.black}
              }}
              {...{orderCosts, productOrder, productInstances, paymentAmount, upchargePercentage, completedPaymentStatuses}}
            />
          )}
        </View>
      </>));
    }

    if (this.props.productOrder.orgId === 850) {
      if (invoiceMode !== invoiceModes.PACKINGSLIP) {
        appendToPage({contentHeight: pageSize.height}, (<>
          <View><GetDisclaimer disclaimerType={'termsAndConditionsDisclaimer'} orgId={this.props.productOrder.orgId}/></View>
        </>));
      }
    }

    var filenameSuffix = {
      client: 'Quote',
      packingSlip: 'Packing Slip',
      manufacturing: 'Factory',
      invoice: 'Invoice'
    }[this.props.invoiceMode || 'client'];

    return (
      <DocumentTitle title={`#${productOrder.orgSpecificId} - ${productOrder.title} - ${moment().format('YYMMDD')} - ${filenameSuffix}`}>
        <View style={{flex: 1, backgroundColor: '#eeeeee'}}>
          {(this.state.resourceStatus !== 'loaded') ? (
            <Loading />
          ) : (
            <ScrollView
              style={{backgroundColor: '#eeeeee'}}
            >
              {!isPrinting && (
                <>
                  <View style={{flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end', marginTop: K.spacing, marginRight: K.spacing}}>
                    {showToggleDiscountButton && <Button
                      label={`${hideInvoiceDiscount ? 'SHOW DISCOUNT' : 'HIDE DISCOUNT'}`}
                      onPress={() => this.handleActveViewDataChange({key: 'hideInvoiceDiscount', value: !hideInvoiceDiscount})}
                      style={{backgroundColor: 'transparent', height: 'auto', minHeight: 0, paddingHorizontal: 0, marginRight: K.spacing}}
                    />
                    }
                    <View style={{marginRight: K.spacing}}>
                      <PickerInput
                        showDownArrow
                        buttonStyle={{...K.shadow}}
                        textStyle={{...K.fonts.label}}
                        options={[
                          {value: invoiceModes.CLIENT, title: 'QUOTE'},
                          {value: invoiceModes.INVOICE, title: 'INVOICE'},
                          ...(_.includes(['member', 'owner'], _.get(activeOrg, 'role')) ? [
                            {value: invoiceModes.PACKINGSLIP, title: 'PACKING SLIP'},
                            {value: invoiceModes.FACTORY, title: 'FACTORY'}
                          ] : [])
                        ]}
                        onChange={({value}) => this.handleActveViewDataChange({key: 'invoiceMode', value})}
                        value={invoiceMode ?? 'quote'}
                      />
                    </View>
                    <Button icon={printIcon} onPress={this.print} style={{...K.defaultIconSize, marginRight: K.spacing, backgroundColor: 'transparent'}}/>
                    <Tooltip text='Download PDF'>
                      <Button icon={pdfIconBlack} style={{...K.defaultIconSize, marginRight: 9, backgroundColor: 'transparent'}} onPress={this.downloadPDF}/>
                    </Tooltip>
                    {_.includes(['member', 'owner'], _.get(activeOrg, 'role')) && (!invoiceMode || _.includes(['client', 'packingSlip'], invoiceMode)) && (
                      <Tooltip text='Download CSV'>
                        <Button icon={downloadIconBlack} style={{...K.defaultIconSize, marginRight: 9, backgroundColor: 'transparent'}} onPress={() => this.downloadCSV({productInstances, filteredColumns, invoiceMode})}/>
                      </Tooltip>
                    )}
                    <form name='generatePdfForm' target='_blank' method='post'>
                      <input type='hidden' name='html' />
                      <input type='hidden' name='token' />
                      <input type='hidden' name='productOrderId' />
                      <input type='hidden' name='options' />
                      <input type='hidden' name='filename' />
                    </form>
                  </View>
                  <View style={{height: K.spacing * 2}}/>
                </>
              )}
              <View dataSet={{invoice: 1}}>
                {_.map(pages, (page, pageNumber) => {
                  return (
                    <>
                      <Page
                        {...{isPrinting}}
                        key={pageNumber}
                        orientation={'landscape'}
                        width={pageSize.width}
                        height={pageSize.height}
                      >
                        <PageHeader {...{pageNumber, pageCount: pages.length, productOrder, activeOrgId: _.get(this.props, 'session.activeOrg.id')}}/>
                        {page.children}
                      </Page>
                      {!isPrinting && <View style={{height: 10}}></View>}
                    </>
                  );
                })}
                {!!isPrinting && (
                  <style>{`
                    @page {
                      size: ${pageSize.width}px ${pageSize.height}px;
                      margin: 0px;
                    }
                    body, html {
                      margin: 0;
                      padding: 0;
                    }
                  `}</style>
                )}
              </View>
            </ScrollView>
          )}
        </View>
      </DocumentTitle>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var productOrderOrgSpecificId = parseInt(ownProps.match.params.productOrderOrgSpecificId);
    var productOrder = _.values(_.get(state, `resources.productOrders.byFieldKeyIndex.orgSpecificId[${productOrderOrgSpecificId}]`))[0];

    if (productOrder) {
      var productInstancesById = _.pickBy(_.get(state, 'resources.productInstances.byId'), {productOrderId: parseInt(productOrder.id)});
      var productsById = _.get(state, 'resources.products.byId');
      var productOptionsById = _.get(state, 'resources.productOptions.byId');
      var productOptionClassifiersById = _.get(state, 'resources.productOptionClassifiers.byId');
      var productPricingRulesById = _.get(state, 'resources.productPricingRules.byId');
      var productRulesById = _.get(state, 'resources.productRules.byId');
      var productPropertiesById = _.get(state, 'resources.productProperties.byId');
    }

    return {
      productOrderOrgSpecificId,
      productOrder,
      productsById,
      productInstancesById,
      productOptionsById,
      productPricingRulesById,
      productRulesById,
      productOptionClassifiersById,
      productPropertiesById,
      ..._.pick(state.activeView.data, ['hideInvoiceDiscount', 'invoiceMode', 'isPrinting'])
    };
  },
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.productInstances, ['trackProductInstances']),
    ..._.pick(resourceActions.productOrders, ['trackProductOrders']),
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productPricingRules, ['trackProductPricingRules']),
    ..._.pick(resourceActions.productProperties, ['trackProductProperties']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
    ..._.pick(resourceActions.productOptionClassifiers, ['trackProductOptionClassifiers']),
    ..._.pick(resourceActions.productPropertyGroups, ['trackProductPropertyGroups']),
    ..._.pick(resourceActions.productRules, ['trackProductRules'])
  }
})(InvoicePage));

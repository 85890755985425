import { PureComponent } from 'react';
import { Text, Link, Label, DocumentTitle, LabelledView, PickerInput, Tooltip, Button, Loading } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect, setAppData } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api, org, products } from '@symbolic/lib';
import moment from 'moment';
import _, { last } from 'lodash';
import K from '~/k';

import { isOrderSearchMatch } from '~/helpers/search';
import { formatPrice } from '~/helpers/price-helper';
import { getNameFor } from '~/helpers/product-order-helper';
import { downloadCsv } from '~/helpers/csv-helper';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';
var { defaultProductOptionFor } = products;

import SecondaryHeader from '~/components/secondary-header';

import moreIcon from '~/assets/more-icon-black.png';
import downloadIcon from '~/assets/download-icon-black.png';
import leftArrowIcon from '~/assets/left-arrow.png';
import { productOptionsByIdFor } from '~/helpers/product-property-helper';
import productPropertiesPopup from '~/components/popups/product-properties-popup';
import sfApiNextRequest from '~/helpers/sf-api-next-request';

var {getLastCompletedProductOrderStatus, getFilteredProductOrderStatuses} = products;

class ProductOrderRow extends PureComponent {
  state = {
    optionsDropdownIsVisible: false,
  };

  toggleArchive = async ({productOrder}) => {
    this.props.updateProductOrder({id: productOrder.id, props: {archived: (productOrder.archived === 1 ? 0 : 1)}});
  };

  render() {
    var {productOrder, productOrderStatuses, productOrderStatusesByKey, activeOrgId, usersById, guestsById, productOrderProductTitle, productCategoriesById, detailLevelFilterValue, productOrderScrewType, productOrderEngravingType, productOrderPlateFinishes, productOrderMechanismFinishes, isGuest, isGuestMode, productOrderLastPaymentDate} = this.props;
    var {optionsDropdownIsVisible} = this.state;

    var productOrderProductCategoryTitle = _.get(_.find(productCategoriesById, {id: productOrder.productCategoryId}), 'title');
    var productOrderOwner = _.get(usersById, productOrder.userId) || _.get(guestsById, productOrder.userId);
    var lastCompletedProductOrderStatus = _.get(getLastCompletedProductOrderStatus({productOrderStatuses, statusData: productOrder.statusData}), 'key');

    var cellStyle = {marginRight: K.spacing, opacity: 0.5};

    return (
      <View
        style={{flexDirection: 'row', borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
        dataSet={{'hover-background-double-gray': 1}}
      >
        <Link
          to={`/orders/${productOrder.orgSpecificId}`}
          style={{flex: 1, paddingVertical: K.spacing / 2, paddingLeft: K.spacing}}
          nativeID={`OrdersPageProductOrderRow-${productOrder.id}`}
        >
          <View
            style={{flexDirection: 'row', alignItems: 'flex-start', opacity: productOrder.archived ? 0.3 : 1}}
          >
            <Text style={{...cellStyle, minWidth: 300, flex: 1, opacity: 1}}>{productOrder.title}</Text>
            {activeOrgId !== 1798 && (
              <>
                <Text style={{...cellStyle, width: 75}}>#{productOrder.orgSpecificId}</Text>
                <Text style={{...cellStyle, width: 150}}>{productOrderProductTitle}</Text>
                <Text style={{...cellStyle, width: 150}}>{productOrderProductCategoryTitle}</Text>
              </>
            )}
            <Text style={{...cellStyle, width: 150}}>{productOrderOwner && `${productOrderOwner.firstName} ${productOrderOwner.lastName}`}</Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) && (<>
              <Text style={{...cellStyle, width: 150}}>{productOrder.firmName}</Text>
            </>)}
            {activeOrgId === 1798 && (<>
              <Text style={{...cellStyle, width: 150}}>{productOrder.contactName}</Text>
              <Text style={{...cellStyle, width: 100}}>#{productOrder.purchaseOrderNumber}</Text>
              <a style={{...cellStyle, width: 150, wordBreak: 'break-all', textDecoration: 'none'}} target="_blank" href={productOrder.externalLink1} rel="noreferrer">
                <Text>{productOrder.externalLink1}</Text>
              </a>
            </>)}
            <Text style ={{...cellStyle, width: 150}}>{_.get(productOrderStatusesByKey, `${lastCompletedProductOrderStatus}.title`)}</Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) && (<>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{moment(productOrder.created).format('M/D/YYYY')}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{moment(productOrder.lastUpdated).format('M/D/YYYY')}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.orderSubTotal', 0))}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.discount', 0))}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>{_.get(productOrder, 'costs.shippingIsTbd') === true ? 'TBD' : `$${formatPrice(_.get(productOrder, 'costs.shipping', 0))}`}</Text>
              <Text style ={{...cellStyle, width: 100, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.tax', 0))}</Text>
            </>)}
            <Text style ={{...cellStyle, width: 125, textAlign: 'right'}}>${formatPrice(_.get(productOrder, 'costs.orderTotal', 0))}</Text>
            <Text style ={{...cellStyle, width: 125, textAlign: 'right'}}>${formatPrice(productOrder.amountPaidInCents)}</Text>
            {(activeOrgId === 850 && (detailLevelFilterValue === 'advanced' && (!isGuest || !isGuestMode))) && (<>
              <Text style={{...cellStyle, width: 100, textAlign: 'right'}}>{productOrderLastPaymentDate}</Text>
              <Text style={{...cellStyle, width: 50, textAlign: 'center'}}>{productOrder.productInstancesCount}</Text>
              <Text style={{...cellStyle, width: 100}}>{productOrderScrewType}</Text>
              <Text style={{...cellStyle, width: 125}}>{productOrderEngravingType}</Text>
              <Text
                style={{...cellStyle, width: 250, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                dataSet={{'hover-white-space-normal': 1}}
              >
                {productOrderPlateFinishes}
              </Text>
              <Text
                style={{...cellStyle, width: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                dataSet={{'hover-white-space-normal': 1}}
              >
                {productOrderMechanismFinishes}
              </Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>{productOrder.estimatedShippingDate ? moment(productOrder.estimatedShippingDate).utc().format('M/D/YYYY') : ''}</Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>{_.get(productOrder, 'commandData.number', '')}</Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>
                {_.get(productOrder, 'commandData.date') ? moment(_.get(productOrder, 'commandData.date')).format('M/D/YYYY') : ''}
              </Text>
              <Text style={{...cellStyle, width: 125, textAlign: 'right'}}>
                {_.get(productOrder, 'commandData.totalInCents') ? `€${formatPrice(_.get(productOrder, 'commandData.totalInCents'))}` : ''}
              </Text>
            </>)}
          </View>
        </Link>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: K.spacing / 2, width: 25}}>
          <TouchableOpacity
            style={{marginHorizontal: K.spacing, opacity: optionsDropdownIsVisible ? 1 : 0.3}}
            onPress={() => this.setState({optionsDropdownIsVisible: !optionsDropdownIsVisible})}
          >
            <Image source={moreIcon} style={{...K.defaultIconSize}}/>
          </TouchableOpacity>
        </View>
        {optionsDropdownIsVisible && (
          <View style={{position: 'absolute', right: 0, top: K.spacing + K.defaultIconSize.height, padding: K.spacing, backgroundColor: '#000', zIndex: 1, borderRadius: K.borderRadius}}>
            <TouchableOpacity
              onPress={() => this.toggleArchive({productOrder})}
              dataSet={{fadeOnHover: 1}}
            >
              <Text style={{color: '#FFF'}}>{productOrder.archived ? 'Unarchive Order' : 'Archive Order'}</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

class OrdersPage extends PureComponent {
  state = {
    isHoveringRow: false,
    activePageNumber: 1,
    isLoading: true
  };

  async componentDidMount() {
    var getOrdersDataResponse = await sfApiNextRequest({path: '/api/configurator/get-configurator-orders-data', body: {
      userId: this.props.session.user.id,
      orgId: await sessionStore.get('activeOrgId'),
      includeAdvancedOrderData: this.props.detailLevelFilterValue === 'advanced'
    }});

    await this.props.trackProducts({products: _.get(getOrdersDataResponse, 'data.products'), reset: true});
    await this.props.trackProductOrders({productOrders: _.get(getOrdersDataResponse, 'data.productOrders'), reset: true});
    await this.props.trackProductCategories({productCategories: _.get(getOrdersDataResponse, 'data.productCategories'), reset: true});
    await this.props.trackProductOptions({productOptions: _.get(getOrdersDataResponse, 'data.productOptions'), reset: true});
    await this.props.trackGuests({guests: _.get(getOrdersDataResponse, 'data.guests'), reset: true});

    setTimeout(() => this.setState({isLoading: false}));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.detailLevelFilterValue !== this.props.detailLevelFilterValue) window.location.reload(); //HINT force reload to fetch new data
  }

  handleFilterChange = ({key, value, updates}) => {
    if (updates) {
      var {ordersPageSettings} = this.props;

      ordersPageSettings = {...ordersPageSettings, ...updates};

      this.props.setAppData({key: 'ordersPageSettings', value: ordersPageSettings, appKey: 'designEngine'});
    }
    else {
      this.props.setAppData({key: `ordersPageSettings.${key}`, value, appKey: 'designEngine'});
    }
  };

  handleExportCurrentListButtonPress = ({productOrders, isGuest, isGuestMode}) => {
    //HINT create headers, exclude empty header over the last column
    var csv = [_.compact(_.map(this.columnHeaderProps, 'title'))];

    var {session: {activeOrg: {role}}, detailLevelFilterValue, productCategoriesById} = this.props;
    var productOrderStatuses = getFilteredProductOrderStatuses({productOrderStatuses: _.get(this.props.session, 'activeOrg.appData.designEngine.productOrderStatuses', []), userRole: role});

    var activeOrgId = this.props.session.activeOrg.id;

    //HINT create rows
    _.forEach(productOrders, (productOrder) => {
      var commandDate = _.get(productOrder, 'commandData.date', '');

      csv.push([
        productOrder.title,
        productOrder.orgSpecificId,
        this.productTitleFor({productOrder}),
        productCategoriesById[`${productOrder.productCategoryId}`].title,
        this.ownerNameFor({productOrder}),
        ...((activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) ? [
          productOrder.firmName || ''
        ] : []),
        _.get(getLastCompletedProductOrderStatus({productOrderStatuses, statusData: productOrder.statusData}), 'title'),
        ...((activeOrgId === 850 && (detailLevelFilterValue === 'advanced')) ? [
          moment(productOrder.created).format('M/D/YYYY'),
          moment(productOrder.lastUpdated).format('M/D/YYYY'),
          `${formatPrice(_.get(productOrder, 'costs.orderSubTotal', 0))}`,
          `${formatPrice(_.get(productOrder, 'costs.discount', 0))}`,
          `${_.get(productOrder, 'costs.shippingIsTbd') === true ? 'TBD' : formatPrice(_.get(productOrder, 'costs.shipping', 0))}`,
          `${formatPrice(_.get(productOrder, 'costs.tax', 0))}`
        ] : []),
        `${formatPrice(_.get(productOrder, 'costs.orderTotal', 0))}`,
        `${formatPrice(_.get(productOrder, 'amountPaidInCents', 0))}`,
        ...(activeOrgId === 850 && (detailLevelFilterValue === 'advanced' && (!isGuest || !isGuestMode)) ? [
          this.lastPaymentDateFor({productOrder}),
          productOrder.productInstancesCount,
          this.screwTypeFor({productOrder}),
          this.engravingTypeFor({productOrder}),
          this.plateFinishesFor({productOrder}),
          this.mechanismFinishesFor({productOrder}),
          productOrder.estimatedShippingDate ? moment(productOrder.estimatedShippingDate).format('M/D/YYYY') : '',
          _.get(productOrder, 'commandData.number', ''),
          `${moment(commandDate).isValid() ? moment(commandDate).format('M/D/YYYY') : commandDate}`,
          `${formatPrice(_.get(productOrder, 'commandData.totalInCents', 0))}`
        ] : [])
      ]);
    });

    var filename = `${getNameFor({orgId: this.props.session.activeOrgId, textToTransform: 'orders'})}-export-${moment().format('YYYY-MM-DD')}`;

    downloadCsv({csv, filename});
  };

  ownerNameFor = ({productOrder}) => {
    var {usersById, guestsById} = this.props;

    var productOrderOwner = _.get(usersById, productOrder.userId) || _.get(guestsById, productOrder.userId);

    return productOrderOwner ? `${productOrderOwner.firstName} ${productOrderOwner.lastName}` : '';
  };

  productTitleFor = ({productOrder}) => {
    var {productsById, productCategoriesById} = this.props;

    return productOrder.productId ? _.get(productsById, `${productOrder.productId}.title`) : _.get(productCategoriesById, `${productOrder.productCategoryId}.title`);
  };

  lastPaymentDateFor = ({productOrder}) => {
    var lastPaymentDate;

    var {session: {activeOrg}} = this.props;
    var productOrderStatuses = _.get(activeOrg, 'appData.designEngine.productOrderStatuses');
    var paymentCompleteStatusKeys = _.map(_.filter(productOrderStatuses, {type: 'payment'}), 'key');

    _.forEach(paymentCompleteStatusKeys, (statusKey) => {
      if (productOrder.statusData[statusKey]) {
        var paymentDate = moment(productOrder.statusData[statusKey].date).format('M/D/YYYY');

        if (!lastPaymentDate) lastPaymentDate = paymentDate;
        else {
          if (moment(paymentDate).isAfter(lastPaymentDate)) paymentDate = lastPaymentDate;
        }
      }
    });

    return lastPaymentDate ? moment(lastPaymentDate).format('M/D/YYYY') : '';
  };

  screwTypeFor = ({productOrder}) => {
    var screwType = '';

    //HINT keypads, floor outlets, wall outlets, reading lamps
    if (_.includes([1, 2, 3, 4], productOrder.productCategoryId) && productOrder.productInstancesCount > 0) {
      var screwTypeProductPropertyId = 3;
      var {productOptionsById} = this.props;

      var defaultOptionId = _.get(defaultProductOptionFor({productPropertyId: screwTypeProductPropertyId}, {productOptionsById}), 'id');
      var screwTypeOptionId = _.get(productOrder, `firstProductInstanceProperties.${screwTypeProductPropertyId}.optionId`, defaultOptionId);

      screwType = _.get(productOptionsById, `${screwTypeOptionId}.title`);
    }

    return screwType;
  };

  engravingTypeFor = ({productOrder}) => {
    var engravingType = '';

    //HINT keypads
    if (_.includes([1], productOrder.productCategoryId)) {
      var engravingsEnabledProductPropertyId = 24;
      var engravingsAreEnabled = _.get(productOrder, `firstProductInstanceProperties.${engravingsEnabledProductPropertyId}.optionId`) === 134;

      if (engravingsAreEnabled) {
        var engravingTypeProductPropertyId = 26;
        var {productOptionsById} = this.props;

        var defaultOptionId = _.get(defaultProductOptionFor({productPropertyId: engravingTypeProductPropertyId}, {productOptionsById}), 'id');
        var engravingTypeOptionId = _.get(productOrder, `firstProductInstanceProperties.${engravingTypeProductPropertyId}.optionId`, defaultOptionId);

        engravingType = _.get(productOptionsById, `${engravingTypeOptionId}.title`);
      }
    }

    return engravingType;
  };

  plateFinishesFor = ({productOrder}) => {
    var plateFinishes = '';

    //HINT keypads, floor outlets, wall outlets, reading lamps
    if (_.includes([1, 2, 3, 4], productOrder.productCategoryId) && productOrder.productInstancesCount > 0) {
      var plateFinishProductPropertyId = 5;
      var {productOptionsById} = this.props;
      var plateFinishOptionIds = _.get(productOrder, 'plateFinishOptionIds', []);

      if (_.isEmpty(plateFinishOptionIds)) {
        plateFinishOptionIds = [
          _.get(defaultProductOptionFor({productPropertyId: plateFinishProductPropertyId}, {productOptionsById}), 'id')
        ];
      }

      plateFinishes = _.join(_.map(plateFinishOptionIds, (optionId) => {
        return _.get(productOptionsById, `${optionId}.title`);
      }), ', ');
    }

    return plateFinishes;
  };

  mechanismFinishesFor = ({productOrder}) => {
    var mechansimFinishes = '';

    //HINT keypads, floor outlets, wall outlets, reading lamps
    if (_.includes([1, 2, 3, 4], productOrder.productCategoryId) && productOrder.productInstancesCount > 0) {
      var mechanismProductPropertyId = 7;
      var {productOptionsById} = this.props;
      var mechanismFinishOptionIds = _.get(productOrder, 'mechanismFinishOptionIds', []);

      if (_.isEmpty(mechanismFinishOptionIds)) {
        mechanismFinishOptionIds = [
          _.get(defaultProductOptionFor({productPropertyId: mechanismProductPropertyId}, {productOptionsById}), 'id')
        ];
      }

      mechansimFinishes = _.join(_.map(mechanismFinishOptionIds, (optionId) => {
        return _.get(productOptionsById, `${optionId}.title`);
      }), ', ');
    }

    return mechansimFinishes;
  };

  get defaultStatusFilterValue() {
    var {props} = this;
    var {session: {activeOrg: {role}}} = props;

    var productOrderStatuses = getFilteredProductOrderStatuses({productOrderStatuses: _.get(props.session, 'activeOrg.appData.designEngine.productOrderStatuses', []), userRole: role});

    if (_.size(productOrderStatuses)) {
      return _.get(_.first(productOrderStatuses), 'key');
    }
    else {
      return null;
    }
  }

  get columnHeaderProps() {
    var {session: {activeOrg}, detailLevelFilterValue} = this.props;
    var {isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    return [
      {title: activeOrg.id === 1798 ? 'Project' : 'Title', style: {minWidth: 300, flex: 1}},
      ...(activeOrg.id === 1798 ? [] : [
        {
          title: getNameFor({orgId: activeOrg.id, textToTransform: 'Order'}),
          style: {width: 75}
        },
      ]),
      {title: 'Product', style: {width: 150}},
      {title: 'Category', style: {width: 150}},
      {title: 'Owner', style: {width: 150}},
      ...((activeOrg.id === 850 && (detailLevelFilterValue === 'advanced')) ? [
        {title: 'Company Name', style: {width: 150}},
      ] : []),
      ...(activeOrg.id === 1798 ? [
        {title: 'Account / Client', style: {width: 150}},
        {title: 'Invoice #', style: {width: 100}},
        {title: 'Salesforce Link', style: {width: 150}}
      ] : []),
      {title: 'Status', style: {width: 150}},
      ...((activeOrg.id === 850 && (detailLevelFilterValue === 'advanced')) ? [
        {title: 'Created', align: 'right', style: {width: 100}},
        {title: 'Updated', align: 'right', style: {width: 100}},
        {title: 'Subtotal', align: 'right', style: {width: 100}},
        {title: 'Discount', align: 'right', style: {width: 100}},
        {title: 'Shipping', align: 'right', style: {width: 100}},
        {title: 'Tax', align: 'right', style: {width: 100}}
      ] : []),
      {
        title: getNameFor({orgId: activeOrg.id, textToTransform: 'Order Total'}),
        align: 'right',
        style: {width: 125}
      },
      {title: 'Amount Paid', align: 'right', style: {width: 125}},
      ...(activeOrg.id === 850 && (detailLevelFilterValue === 'advanced' && (!isGuest || !isGuestMode)) ? [
        {title: 'Last Payment', align: 'right', style: {width: 100}},
        {title: 'Items', align: 'center', style: {width: 50}},
        {title: 'Screw Type', style: {width: 100}},
        {title: 'Engraving Type', style: {width: 125}},
        {title: 'Plate Finish(es)', style: {width: 250}},
        {title: 'Mechanism Finish(es)', style: {width: 200}},
        {title: 'Estimated Ship', align: 'right', style: {width: 125}},
        {title: 'Command #', align: 'right', style: {width: 125}},
        {title: 'Command Date', align: 'right', style: {width: 125}},
        {title: 'Command Total', align: 'right', style: {width: 125}},
      ] : []),
      {title: '', style: {width: 25}}
    ];
  }

  handleUpdatePageRows = ({value}) => {
    this.handleFilterChange({key: 'pageRowsFilterValue', value});
    this.setState({activePageNumber: 1});
  };

  handleUpdateActivePageNumber = ({increment, decrement, totalPages}) => {
    if (increment && this.state.activePageNumber < totalPages) {
      this.setState({activePageNumber: this.state.activePageNumber + 1});
    }
    else if (decrement && this.state.activePageNumber > 1) {
      this.setState({activePageNumber: this.state.activePageNumber - 1});
    }
  };

  render() {
    var {productOrders, session, usersById, guestsById, updateProductOrder, orderOwnerFilterValue, archivedFilterValue, statusFilterValue, pageRowsFilterValue, productCategoryFilterValue, productCategoriesById, detailLevelFilterValue} = this.props;
    var {isLoading, activePageNumber} = this.state;
    var {activeOrg, user} = session;
    var {appData, role} = activeOrg;
    var {isEmployee, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    var searchTerm = this.props.searchTerm;
    var productOrderStatuses = getFilteredProductOrderStatuses({productOrderStatuses: _.get(appData, 'designEngine.productOrderStatuses', []), userRole: role});
    var productOrderStatusesByKey = _.mapKeys(productOrderStatuses, (item) => item.key);
    var statusFilterOptions = _.map([{title: 'All', type: 'all'}, ...productOrderStatuses], (status) => ({value: status.key, title: status.title}));
    var productCategoriesFilterOptions = _.map([{title: 'All', id: 'all'}, ..._.map(productCategoriesById)], (productCategory) => ({value: productCategory.id, title: productCategory.title}));

    //HINT map last completed status key to product orders
    productOrders = _.map(productOrders, (productOrder) => {
      var lastCompletedStatus = getLastCompletedProductOrderStatus({productOrderStatuses, statusData: productOrder.statusData});

      return {...productOrder, lastCompletedStatus};
    });

    if (activeOrg.id === 850) { //HINT MNA orders awaiting employee action
      var getOrdersForLastCompletedStatus = (statusKey) => {
        return _.filter(productOrders, ({lastCompletedStatus, archived}) => {
          return _.get(lastCompletedStatus, 'key') === statusKey && archived === 0;
        });
      };

      var submittedForApprovalOrders = getOrdersForLastCompletedStatus('submittedForApproval');
      var depositReceivedOrders = getOrdersForLastCompletedStatus('readyToPayDeposit');
      var finalPaymentReceivedOrders = getOrdersForLastCompletedStatus('finalPaymentReceived');
    }

    statusFilterValue = statusFilterValue || this.defaultStatusFilterValue;

    var orderOwnerFilterOptions = [
      {value: 'all', title: 'Anyone'},
      {value: 'self', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'My Orders'})}
    ];

    var detailLevelFilterOptions = [
      {value: 'basic', title: 'Basic'},
      {value: 'advanced', title: 'Advanced'}
    ];

    var pageRowsFilterOptions = [
      {value: 50, title: '50'},
      {value: 100, title: '100'},
      {value: 150, title: '150'},
      {value: 200, title: '200'},
      {value: 250, title: '250'}
    ];

    if (orderOwnerFilterValue !== 'all') {
      productOrders = _.filter(productOrders, productOrder => {
        if (orderOwnerFilterValue === 'self') return productOrder.userId === user.id;
      });
    }

    if (archivedFilterValue !== 'all') {
      productOrders = _.filter(productOrders, productOrder => {
        if (archivedFilterValue === 'active') return productOrder.archived === 0;
        else if (archivedFilterValue === 'archived') return productOrder.archived === 1;
      });
    }

    if (statusFilterValue !== 'All') {
      productOrders = _.filter(productOrders, ({lastCompletedStatus}) => _.get(lastCompletedStatus, 'key') === statusFilterValue);
    }

    if (productCategoryFilterValue !== 'all') {
      productOrders = _.filter(productOrders, ({productCategoryId}) => {
        return productCategoryId === parseInt(productCategoryFilterValue);
      });
    }

    var {isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    if (isGuestMode) {
      productOrders = _.filter(productOrders, {userId: user.id});
    }

    if (searchTerm) {
      productOrders = _.filter(productOrders, productOrder => {
        productOrder.ownerName = this.ownerNameFor({productOrder});

        return isOrderSearchMatch(searchTerm, productOrder);
      });
    }

    productOrders = _.orderBy(productOrders, 'id', 'desc');

    var totalPages = _.ceil(_.size(productOrders) / pageRowsFilterValue);

    var filterInputStyles = {
      style: {height: K.inputHeight},
      buttonStyle: {backgroundColor: K.colors.doubleGray, maxWidth: 150}
    };

    var requiresAttentionStyles = {
      style: {
        outerView: {marginRight: K.spacing}
      },
      buttonStyle: {
        backgroundColor: K.colors.doubleGray,
        height: K.inputHeight,
        width: 50
      }
    };

    if (isLoading) {
      return (
        <Loading text={'Loading up\n\nThis can take a few seconds\n\nHold tight...'}/>
      );
    }
    else {
      return (
        <DocumentTitle title={getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'My Orders'})}>
          <View style={{flex: 1, position: 'relative'}}>
            <SecondaryHeader
              title={getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Orders'})}
              rightComponent={(
                <View style={{flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                  {(activeOrg.id === 850 && (isEmployee && !isGuestMode)) && (
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                      <Tooltip text='Submitted for MNA Review'>
                        <LabelledView
                          label={'SUB'}
                          styles={{...requiresAttentionStyles.style}}
                        >
                          <Button
                            style={{...requiresAttentionStyles.buttonStyle}}
                            onPress={() => {
                              this.handleFilterChange({updates: {
                                orderOwnerFilterValue: 'all',
                                archivedFilterValue: 'active',
                                productCategoryFilterValue: 'all',
                                statusFilterValue: 'submittedForApproval'
                              }});
                            }}
                          >
                            <Text>{submittedForApprovalOrders.length}</Text>
                          </Button>
                        </LabelledView>
                      </Tooltip>
                      <Tooltip text='Confirmed - Deposit Received'>
                        <LabelledView
                          label={'DR'}
                          styles={{...requiresAttentionStyles.style}}
                        >
                          <Button
                            style={{...requiresAttentionStyles.buttonStyle}}
                            onPress={() => {
                              this.handleFilterChange({updates: {
                                orderOwnerFilterValue: 'all',
                                archivedFilterValue: 'active',
                                productCategoryFilterValue: 'all',
                                statusFilterValue: 'readyToPayDeposit'
                              }});
                            }}
                          >
                            <Text>{depositReceivedOrders.length}</Text>
                          </Button>
                        </LabelledView>
                      </Tooltip>
                      <Tooltip text='Final Payment Received'>
                        <LabelledView
                          label={'FPR'}
                          styles={{...requiresAttentionStyles.style}}
                        >
                          <Button
                            style={{...requiresAttentionStyles.buttonStyle}}
                            onPress={() => {
                              this.handleFilterChange({updates: {
                                orderOwnerFilterValue: 'all',
                                archivedFilterValue: 'active',
                                productCategoryFilterValue: 'all',
                                statusFilterValue: 'finalPaymentReceived'
                              }});
                            }}
                          >
                            <Text>{finalPaymentReceivedOrders.length}</Text>
                          </Button>
                        </LabelledView>
                      </Tooltip>
                    </View>
                  )}
                  <LabelledView label={'Owner'} styles={{outerView: {marginRight: K.spacing}}}>
                    <PickerInput
                      key={orderOwnerFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={orderOwnerFilterOptions}
                      value={orderOwnerFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'orderOwnerFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView
                    label={'Type'}
                    styles={{outerView: {marginRight: K.spacing}}}
                  >
                    <PickerInput
                      key={archivedFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={[
                        {value: 'active', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Active Orders'})},
                        {value: 'archived', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'Archived Orders'})},
                        {value: 'all', title: getNameFor({orgId: this.props.session.activeOrg.id, textToTransform: 'All Orders'})},
                      ]}
                      value={archivedFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'archivedFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView
                    label={'Category'}
                    styles={{outerView: {marginRight: K.spacing}}}
                  >
                    <PickerInput
                      key={productCategoryFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={productCategoriesFilterOptions}
                      value={productCategoryFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'productCategoryFilterValue', value})}
                    />
                  </LabelledView>
                  <LabelledView label='Status' styles={{outerView: {marginRight: K.spacing}}}>
                    <PickerInput
                      key={statusFilterValue}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                      options={statusFilterOptions}
                      value={statusFilterValue}
                      onChange={({value}) => this.handleFilterChange({key: 'statusFilterValue', value})}
                    />
                  </LabelledView>
                  {(isEmployee && !isGuestMode) && (
                    <LabelledView label='View' styles={{outerView: {marginRight: K.spacing}}}>
                      <PickerInput
                        style={{...filterInputStyles.style}}
                        buttonStyle={{...filterInputStyles.buttonStyle}}
                        showDownArrow={true}
                        options={detailLevelFilterOptions}
                        value={detailLevelFilterValue}
                        onChange={({value}) => this.handleFilterChange({key: 'detailLevelFilterValue', value})}
                      />
                    </LabelledView>
                  )}
                  <LabelledView label={'Items/Page'}>
                    <PickerInput
                      value={pageRowsFilterValue}
                      options={pageRowsFilterOptions}
                      onChange={this.handleUpdatePageRows}
                      style={{...filterInputStyles.style}}
                      buttonStyle={{...filterInputStyles.buttonStyle}}
                      showDownArrow={true}
                    />
                    {totalPages > 1 && (
                      <div style={{position: 'absolute', top: K.spacing * 3.5}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: K.spacing}}>
                          <Button
                            onPress={() => this.handleUpdateActivePageNumber({decrement: true, totalPages})}
                            icon={leftArrowIcon}
                            style={{backgroundColor: 'transparent', ...K.defaultIconSize * 2, alignItems: 'center', opacity: activePageNumber === 1 ? 0.2 : 1}}
                          />
                          <Button
                            onPress={() => this.handleUpdateActivePageNumber({increment: true, totalPages})}
                            icon={leftArrowIcon}
                            style={{backgroundColor: 'transparent', ...K.defaultIconSize * 2, transform: 'rotate(180deg)', alignItems: 'center', opacity: activePageNumber === totalPages ? 0.2 : 1}}
                          />
                        </div>
                      </div>
                    )}
                  </LabelledView>
                </View>
              )}
              belowHeaderComponent={(
                <>
                  {activeOrg.id === 850 && (
                    <View style={{marginTop: K.spacing * 2, flexDirection: 'column', flex: 1, justifyContent: 'flex-end'}}>
                      <View style={{flexDirection: 'row'}}>
                        <Tooltip left text='Export Current List to CSV'>
                          <Button
                            style={{backgroundColor: K.color, margin: K.margin, ...K.defaultIconSize}}
                            onPress={() => this.handleExportCurrentListButtonPress({productOrders, isGuest, isGuestMode})}
                            icon={downloadIcon}
                          />
                        </Tooltip>
                        {/* {_.includes([1, 2], user.id) && ( //HINT only visible to Max & Chris
                          <Tooltip left text='update costs for 50 product orders with effective pricing date 2024-07-19 00:00:00'>
                            <Button
                              style={{backgroundColor: K.color, margin: K.margin, ...K.defaultIconSize}}
                              onPress={async () => await api.request({uri: '/configurator/update-product-order-costs'})}
                              icon={downloadIcon}
                            />
                          </Tooltip>
                        )} */}
                      </View>
                    </View>
                  )}
                </>
              )}
            ></SecondaryHeader>
            <ScrollView horizontal={true} style={{flex: 1}} contentContainerStyle={{minWidth: '100%'}}>
              <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: K.spacing, paddingBottom: 200}}>
                <View style={{marginHorizontal: K.spacing, justifyContent: 'space-evenly', paddingVertical: K.spacing, paddingLeft: K.spacing, flexDirection: 'row', alignItems: 'flex-end'}}>
                  {_.map(this.columnHeaderProps, ({title, style, align}, index) => (
                    <View key={index}
                      style={{
                        ...style,
                        ...(align === 'right' && {alignItems: 'flex-end', textAlign: 'right'}),
                        ...(align === 'center' && {alignItems: 'center', textAlign: 'center'}),
                        ...(index + 1 === this.columnHeaderProps.length ? {marginRight: 0} : {marginRight: K.spacing})
                      }}
                    >
                      <Label>{title}</Label>
                    </View>
                  ))}
                </View>
                <View style={{marginBottom: K.spacing * 5}}>
                  {_.map(_.slice([...productOrders], pageRowsFilterValue * (activePageNumber - 1), pageRowsFilterValue * activePageNumber), (productOrder) => {
                    return (
                      <ProductOrderRow
                        key={productOrder.id}
                        productOrderProductTitle={this.productTitleFor({productOrder})}
                        {
                          ...{productOrder, productOrderStatuses, productOrderStatusesByKey, activeOrgId: activeOrg.id, usersById, guestsById, isGuest, isGuestMode, updateProductOrder, productCategoriesById, detailLevelFilterValue,
                          ...((activeOrg.id === 850 && (detailLevelFilterValue === 'advanced')) ? {
                            productOrderLastPaymentDate: this.lastPaymentDateFor({productOrder}),
                            productOrderScrewType: this.screwTypeFor({productOrder}),
                            productOrderEngravingType: this.engravingTypeFor({productOrder}),
                            productOrderPlateFinishes: this.plateFinishesFor({productOrder}),
                            productOrderMechanismFinishes: this.mechanismFinishesFor({productOrder}),
                          } : {})}
                        }
                      />
                    );
                  })}
                </View>
              </ScrollView>
            </ScrollView>
          </View>
        </DocumentTitle>
      );
    }
  }
}

export default connect({
  mapState: (state) => {
    var orderOwnerFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.orderOwnerFilterValue', 'all');
    var archivedFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.archivedFilterValue', 'active');
    var statusFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.statusFilterValue', 'All');
    var pageRowsFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.pageRowsFilterValue', 100);
    var productCategoryFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.productCategoryFilterValue', 'all');
    var detailLevelFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.detailLevelFilterValue', 'basic');

    return {
      productOrders: state.resources.productOrders.byId,
      usersById: state.resources.users.byId,
      guestsById: state.resources.guests.byId,
      productsById: state.resources.products.byId,
      productOptionsById: state.resources.productOptions.byId,
      productCategoriesById: state.resources.productCategories.byId,
      searchTerm: state.activeView.data.searchTerm,
      orderOwnerFilterValue, archivedFilterValue, statusFilterValue, pageRowsFilterValue, productCategoryFilterValue,
      detailLevelFilterValue,
      orderPageSettings: _.get(state, 'session.user.appData.designEngine.ordersPageSettings', {})
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productOrders, ['trackProductOrders', 'updateProductOrder']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
    ..._.pick(resourceActions.guests, ['trackGuests']),
    setAppData
  }
})(OrdersPage);
